<!-- Search Bar -->
<mat-form-field
  class="search-form-field w-100"
  (mouseenter)="showResults = true"
>
  <mat-icon matPrefix>search</mat-icon>
  <input
    type="text"
    matInput
    placeholder="Rechercher un élément ( Entrez au moins 3 caractères )"
    (keyup)="applyFilter($event)"
    [(ngModel)]="searchText"
  />
  <button
    *ngIf="searchText"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="resetSearchText()"
  >
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>

<!-- Results Overlay -->
<div
  class="search-overlay"
  [hidden]="!showResults"
  (mouseleave)="showResults = false"
  [ngClass]="{ 'hide-margin-overlay': getResultsToShow().length == 0 }"
>
  <div class="search-overlay-content">
    <div
      fxLayout="column"
      fxLayout.gt-xs="row"
      fxLayoutAlign="space-between start"
      fxLayoutGap="20px"
    >
      <ng-container *ngFor="let item of getResultsToShow()">
        <div
          fxLayout="row wrap"
          fxLayoutAlign="start start"
          fxLayoutGap="20px"
          fxFlex="50"
        >
          <mat-list>
            <div
              mat-subheader
              fxLayout="row"
              fxLayoutAlign="space-between center"
              fxLayoutGap="20px"
            >
              <span>{{ getHeaderLabel(item) }}</span>
              <span class="header-count-results"
                >{{ searchResults[item].length }} résultats</span
              >
            </div>
            <mat-list-item
              *ngFor="let result of searchResults[item]"
              (click)="goToLink(item, result.id)"
              class="overlay-mat-list-item"
            >
              <svg-icon
                matListItemIcon
                [src]="'assets/icon/' + apiEndpointList[item].icon + '.svg'"
                fxLayout="row"
                fxLayoutAlign="center center"
                [svgStyle]="{ 'height.px': 24 }"
              ></svg-icon>
              <div matListItemTitle>
                {{ apiEndpointList[item].titleCell(result) }}
              </div>
              <div matListItemLine *ngIf="apiEndpointList[item].lineCell">
                {{ apiEndpointList[item].lineCell(result) }}
              </div>
            </mat-list-item>
          </mat-list>
        </div>
      </ng-container>
    </div>
  </div>
  <mat-divider></mat-divider>
</div>
