import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

interface PaymentDialog {
  config: {
    header: {
      icon?: string;
      label?: string;
    };
    content: {
      img?: string;
      title?: string;
      text?: string;
    };
    actions: {
      cancel?: string;
      submit?: string;
    };
  },
  data: any
}

@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss']
})
export class PaymentDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: PaymentDialog, public dialogRef: MatDialogRef<DeleteDialogComponent>,) { }

  submitDialogForm() {
    this.dialogRef.close(true);
  }
}
