import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';

import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { AdminGuard, AuthGuard } from '@core/guard/auth.guard';
import { Role } from './data/models/role.model';
import { PaymentSuccessfullComponent } from '@shared/components/payment-successfull/payment-successfull.component';
import { PaymentCancelComponent } from '@shared/components/payment-cancel/payment-cancel.component';

const routes: Routes = [
  // Fallback when no prior routes is matched
  // { path: '**', redirectTo: '/auth/login', pathMatch: 'full' },
  /*
http://localhost:4200/auth/reset-password/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImU1NTU3NGE1LThkMTgtNDFmMC1hYWU5LWY1MjZmMTQ4YjdlOSIsImNvZGUiOiJUOE5GKU8hRjZvTGYyZWVkKWZ4ZDBvZl5qJFE5Vlg3aXRObzBwa3Brd0llTFoxT0VqWU9PTERkMHljZzdvJTV0IiwiaWF0IjoxNzAxMDkwOTQ2LCJleHAiOjE3MDIzMDA1NDZ9.PbMnEwer7dUGEhhN2FbCz5L-8hjgkANTXpEyB_GMk-s
  */
  {
    path: 'login',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('@modules/auth/auth.module').then(m => m.AuthModule)
  },
  { path: 'auth/reset-password/:id', redirectTo: 'login/reset-password/:id', pathMatch: 'full' },
  {
    path: 'payment-success/:id/:order',
    component: PaymentSuccessfullComponent
  },
  {
    path: 'payment-cancel/:id/:order',
    component: PaymentCancelComponent
  },
  {
    path: '',
    loadChildren: () =>
      import('@modules/landing/landing.module').then(m => m.LandingModule)
  },
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('@modules/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'admin',
        canActivate: [AdminGuard],
        loadChildren: () =>
          import('@modules/admin/admin.module').then(m => m.AdminModule),
        data: { requiredRoles: [Role.Admin, Role.Root] }
      },
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
