import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-text',
  template: `
    <h4 class="formly-text">{{key}}</h4>
  `,
  styles: [
    `
  h4{
      font-size:18px;
      font-weigth:600!important;
      margin-bottom:15px;
    }
       `
  ]
})
export class TextFieldType extends FieldType<FieldTypeConfig> { }