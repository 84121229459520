import { Component, EventEmitter, Output } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-stepper',
  template: `
    <mat-horizontal-stepper>
      <mat-step *ngFor="let step of field.fieldGroup; let index = index; let last = last" [stepControl]="getStepControl(step)" [completed]="step.props?.['isCompleted']">
        <ng-template matStepLabel *ngIf="step.props">{{ step.props.label }}</ng-template>
        <div class="step-content">
          <formly-field [field]="step"></formly-field>

          <div class="flex-btn-group">
            <button matStepperPrevious *ngIf="index === 0" mat-stroked-button type="button" color="primary" type="submit" (click)="cancelForm()">
              <div class="flex-btn">
                Annuler
              </div>
            </button>
            <button matStepperPrevious *ngIf="index !== 0" mat-stroked-button type="button" color="primary">
              <div class="flex-btn">
                <svg-icon *ngIf="field.props && field.props['button'].previous.icon" [src]="'assets/icon/' + field.props['button'].previous.icon + '.svg'" fxLayout="row" fxLayoutAlign="center center"></svg-icon>
                {{ field.props && field.props['button'].previous ? field.props['button'].previous.label : 'Etape précédente' }}
              </div>
            </button>

            <button matStepperNext *ngIf="!last" mat-flat-button color="primary" type="button" [disabled]="!isStepValid(step)">
              <div class="flex-btn">
                {{ field.props && field.props['button'].next ? field.props['button'].next.label : 'Etape suivante' }}
                <svg-icon *ngIf="field.props && field.props['button'].next.icon" [src]="'assets/icon/' + field.props['button'].next.icon + '.svg'" fxLayout="row" fxLayoutAlign="center center"></svg-icon>
              </div>
            </button>

            <button *ngIf="last" mat-flat-button color="primary" [disabled]="!form.valid" type="submit">
              <div class="flex-btn">
                <svg-icon *ngIf="field.props && field.props['button'].submit.icon" [src]="'assets/icon/' + field.props['button'].submit.icon + '.svg'" fxLayout="row" fxLayoutAlign="center center"></svg-icon>
                {{ field.props && field.props['button'].submit ? field.props['button'].submit.label : 'Valider' }}
              </div>
            </button>
          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  `,
  styles: [
    `
    .step-content{
      padding-top:12px;
    }

    .flex-btn-group{
      display:flex;
      justify-content:flex-end;
      gap:20px;
      margin: 10px 0px;
    }

    .flex-btn{
      display:flex;
      justify-content:center;
      align-items:center;
      gap:5px;
    }

    `
  ]
})
export class FormlyFieldStepper extends FieldType {

  isValid(field: FormlyFieldConfig): boolean {
    if (field.key) {
      if (field.formControl) {
        return field.formControl.valid;
      }
      else return false;
    }
    return field.fieldGroup ? field.fieldGroup.every((f) => this.isValid(f)) : true;
  }

  isStepValid(step: FormlyFieldConfig): boolean {
    return this.isValid(step);
  }

  getStepControl(step: FormlyFieldConfig): any {
    // Vérifier que step.fieldGroup n'est pas vide
    // et retourner le contrôle du premier champ du groupe

    return step.fieldGroup && step.fieldGroup.length > 0 ? step.fieldGroup[0].formControl : undefined;
  }

  cancelForm() {
    this.options.formState.cancel = !this.options.formState.cancel;
  }

}


