<!-- Header -->
<div
  [fxLayout]="'column'"
  [fxLayoutAlign]="
    config.searchOptions?.active ? 'start none' : 'space-between center'
  "
  fxLayoutGap="10px"
>
  <!-- Header -->
  <div
    [fxLayout]="config.searchOptions?.active ? 'column' : 'row'"
    [fxLayoutAlign]="
      config.searchOptions?.active ? 'start none' : 'space-between center'
    "
    fxLayoutGap="5px"
    style="margin-bottom: 20px; width: 100%"
  >
    <!-- Row 1 -->
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
      <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="0px">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <h1 *ngIf="config.title" class="title">{{ config.title }}</h1>

          <div
            *ngIf="config.badge"
            class="table-badge mat-primary-background-pastel mat-primary-color"
            fxLayout="column"
            fxLayoutAlign="center center"
            fxLayoutGap="0px"
            [ngClass]="
              config.badgeOptions && config.badgeOptions.color == 'warn'
                ? ['mat-warn-color', 'mat-warn-background-pastel']
                : []
            "
          >
            {{ resultsLength + " résultats" }}
          </div>
        </div>
        <p class="text-grey subtitle" *ngIf="config.subtitle">
          {{ config.subtitle }}
        </p>
      </div>
    </div>
    <!--/ Row 1 -->
    <!-- Row 2 -->
    <div
      fxLayout="row"
      [fxLayoutAlign]="
        config.searchOptions?.active ? 'space-between none' : 'flex-end none'
      "
      fxLayoutGap="20px"
    >
      <!-- Search -->
      <mat-form-field
        *ngIf="config.searchOptions?.active"
        class="search-input"
        fxFlex="50"
        fxFlex.gt-xs="40"
      >
        <mat-icon matPrefix>
          <svg-icon
            [src]="
              'assets/icon/' +
              (config.searchOptions?.icon
                ? config.searchOptions?.icon
                : 'search-light') +
              '.svg'
            "
            fxLayout="row"
            fxLayoutAlign="center center"
            [svgStyle]="{ 'height.px': 22, 'width.px': 22 }"
          ></svg-icon>
        </mat-icon>

        <mat-label>Rechercher un élément</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Entrez votre recherche"
          #input
          [(ngModel)]="searchFormValue"
        />

        <button
          *ngIf="searchFormValue"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="resetFilter()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <div
        fxLayout="row"
        fxLayoutAlign="flex-end start"
        fxLayoutGap="10px"
        class="actions-btn-container"
        [fxFlex]="
          (config.customBtnOptions?.length == 0 ||
            config.customBtnOptions == undefined) &&
          (!config.filterBtnOptions ||
            config.filterBtnOptions.filters.length == 0)
            ? '100'
            : '50'
        "
      >
        <!-- Custom Buttons -->
        <ng-container *ngIf="config.customBtnOptions">
          <button
            *ngFor="let customButton of config.customBtnOptions"
            mat-button
            [color]="customButton.color ?? 'default'"
            [ngClass]="{
            'mat-mdc-button mat-mdc-raised-button':
              customButton?.styleType === 'raised',
            'mdc-button--outlined mat-mdc-outlined-button':
              customButton?.styleType === 'stroked' ||
              customButton?.styleType === undefined,
            'mat-mdc-unelevated-button': customButton?.styleType === 'flat',
            'mat-mdc-menu-item mdc-list-item':
              customButton?.styleType === 'menu',
          }"
            (click)="customActionRow(customButton.value, customButton)"
          >
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <svg-icon
                *ngIf="customButton.icon"
                [src]="'assets/icon/' + customButton.icon('') + '.svg'"
                fxLayout="row"
                fxLayoutAlign="center center"
              ></svg-icon>
              <span>{{ customButton.label("") }} </span>
            </div>
          </button>
        </ng-container>
        <!--/ Custom Buttons -->

        <!-- Filter Results Button -->

        <ng-container
          *ngIf="config.filterBtnOptions && config.filterBtnOptions.active"
        >
          <button
            mat-stroked-button
            [color]="config.filterBtnOptions.color ?? 'default'"
            [ngClass]="{}"
            (click)="openFilterMenu()"
            [matMenuTriggerFor]="filterMenu"
          >
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <svg-icon
                *ngIf="config.filterBtnOptions.icon"
                [src]="'assets/icon/' + config.filterBtnOptions.icon + '.svg'"
                fxLayout="row"
                fxLayoutAlign="center center"
              ></svg-icon>
              <span>{{ config.filterBtnOptions.label }} </span>
            </div>
          </button>

          <!-- Filter Menu -->
          <mat-menu #filterMenu="matMenu" (mouseleave)="closeFilterMenu()">
            <div
              class="filter-menu"
              fxLayout="column"
              fxLayoutAlign="start strech"
              fxLayoutGap="0px"
              (click)="$event.stopPropagation()"
            >
              <!-- Header Filter -->
              <div
                class="filter-menu-header"
                fxLayout="column"
                fxLayoutAlign="start strech"
                fxLayoutGap="10px"
              >
                <h1>Liste des filtres</h1>

                <span class="text-grey"
                  >Sélectionnez des filtres parmi la liste ci-dessous</span
                >
                <mat-divider class="w-100"></mat-divider>
              </div>
              <!-- / Header Filter -->

              <!-- 
            <mat-accordion multi> -->

              <div
                fxLayout="column"
                fxLayoutAlign="start stretch"
                fxLayoutGap="5px"
                class=" "
              >
                <ng-container
                  *ngFor="
                    let filter of config.filterBtnOptions.filters;
                    let i = index;
                    let first = first;
                    let last = last
                  "
                >
                  <div
                    fxLayout="column"
                    fxLayoutAlign="start strech"
                    fxLayoutGap="5px"
                    class="filter-item"
                  >
                    <h2 class="filter-item-title">{{ filter.name }}</h2>
                    <!--  
              <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <span matListItemTitle class="filter-title">
                      {{ filter.name }}</span
                    >
                  </mat-panel-title>
                </mat-expansion-panel-header>
-->

                    <ng-container [ngSwitch]="filter.fieldType">
                      <!--  *ngSwitchCase="true" -->
                      <!-- ngSwitchDefault  -->

                      <!-- Chip Type -->
                      <ng-container *ngSwitchCase="'chip'">
                        <mat-chip-listbox
                          class="filter-type-chip"
                          [multiple]="filter.multiple ?? false"
                          (change)="onSelectionChip($event, filter)"
                          [formControl]="getFilterFormControl(filter.name)"
                        >
                          <ng-container
                            *ngFor="
                              let data of !filter.contentLoad
                                ? getFilterData(filter)
                                : filter.fieldData.data
                            "
                          >
                            <mat-chip-option [value]="data">
                              <svg-icon
                                matChipAvatar
                                *ngIf="data.icon"
                                [src]="'assets/icon/' + data.icon + '.svg'"
                                fxLayout="row"
                                fxLayoutAlign="center center"
                              ></svg-icon>
                              {{ data.label }}</mat-chip-option
                            >
                          </ng-container>
                        </mat-chip-listbox>
                      </ng-container>
                      <!-- / Chip Type -->

                      <!-- Checkbox Type -->
                      <ng-container *ngSwitchCase="'checkbox'">
                        <mat-selection-list
                          [multiple]="filter.multiple ?? false"
                          #filterSelection
                          (selectionChange)="
                            onSelection(
                              $event,
                              filterSelection.selectedOptions,
                              filter
                            )
                          "
                          color="primary"
                        >
                          <ng-container
                            *ngFor="
                              let data of !filter.contentLoad
                                ? getFilterData(filter)
                                : filter.fieldData.data
                            "
                          >
                            <mat-list-option [value]="data">{{
                              data.label
                            }}</mat-list-option>
                          </ng-container>
                        </mat-selection-list>
                      </ng-container>
                      <!-- / Checkbox Type -->

                      <!-- Autocomplete Type -->
                      <ng-container *ngSwitchCase="'autocomplete'">
                        <div
                          class="filter-type-autocomplete"
                          *ngIf="getFilterFormControl(filter.name)"
                        >
                          <mat-form-field class="w-100">
                            <mat-icon matPrefix>
                              <svg-icon
                                *ngIf="config.filterBtnOptions.icon"
                                [src]="
                                  'assets/icon/' +
                                  'search-document-light' +
                                  '.svg'
                                "
                                fxLayout="row"
                                fxLayoutAlign="center center"
                              ></svg-icon>
                            </mat-icon>
                            <input
                              type="text"
                              placeholder="Recherchez un élément"
                              aria-label="Number"
                              matInput
                              [formControl]="getFilterFormControl(filter.name)"
                              [matAutocomplete]="auto"
                            />
                            <mat-autocomplete
                              autoActiveFirstOption
                              #auto="matAutocomplete"
                              [displayWith]="displayFnAutoCompleteFilter"
                            >
                              <ng-container
                                *ngFor="let option of filter.fieldData.data"
                              >
                                <mat-option [value]="option">{{
                                  option.label
                                }}</mat-option>
                              </ng-container>
                            </mat-autocomplete>
                          </mat-form-field>
                        </div>
                      </ng-container>
                      <!--/ Autocomplete Type -->

                      <!-- Slider Type -->
                      <ng-container *ngSwitchCase="'slider'">
                        <div class="w-100"></div>
                      </ng-container>
                      <!-- / Slider Type -->

                      <!-- Date Type -->
                      <ng-container *ngSwitchCase="'date'">
                        <div class="filter-type-date">
                          <mat-form-field
                            class="w-100"
                            *ngIf="getFilterFormGroup(filter.name)"
                          >
                            <mat-label>Saisir un intervalle de dates</mat-label>
                            <mat-date-range-input
                              [formGroup]="getFilterFormGroup(filter.name)"
                              [rangePicker]="picker"
                            >
                              <input
                                matStartDate
                                formControlName="start"
                                placeholder="Date de début "
                                (dateChange)="
                                  datePickerChange('start', $event, filter)
                                "
                              />
                              <input
                                matEndDate
                                formControlName="end"
                                placeholder="Date de fin"
                                (dateChange)="
                                  datePickerChange('end', $event, filter)
                                "
                              />
                            </mat-date-range-input>
                            <mat-datepicker-toggle
                              matIconSuffix
                              [for]="picker"
                            ></mat-datepicker-toggle>
                            <mat-date-range-picker
                              #picker
                            ></mat-date-range-picker>
                          </mat-form-field>
                        </div>
                      </ng-container>
                      <!-- / Date Type -->

                      <!-- Toggle Type -->
                      <ng-container *ngSwitchCase="'toggle'">
                        <mat-slide-toggle
                          [labelPosition]="'before'"
                          (change)="toggleChange($event, filter)"
                          [formControl]="getFilterFormControl(filter.name)"
                        >
                          {{ filter.fieldLabel }}
                        </mat-slide-toggle>
                      </ng-container>
                      <!-- / Toggle Type -->
                    </ng-container>

                    <!--  
              </mat-expansion-panel>
              -->
                  </div>
                </ng-container>
              </div>
              <!--  
            </mat-accordion>
            --></div>

            <!-- Filter Actions -->
            <div
              class="filter-actions"
              fxLayout="column"
              fxLayoutAlign="start stretch"
              fxLayoutGap="0px"
            >
              <mat-divider class="w-100"></mat-divider>
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                fxLayoutGap="10px"
                class="filter-actions-content"
              >
                <span class="filter-actions-results mat-primary-color"
                  >{{ resultsLength }} Résultats
                </span>
                <button
                  mat-stroked-button
                  color="primary"
                  (click)="closeFilterMenu()"
                >
                  Fermer
                </button>
              </div>
            </div>
            <!-- / Filter Actions -->
          </mat-menu>
        </ng-container>

        <!-- / Filter Results Button -->

        <!-- Add Button -->
        <button
          mat-stroked-button
          [ngClass]="{}"
          color="primary"
          (click)="addRow()"
          *ngIf="config.addOptions?.active"
        >
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <svg-icon
              src="assets/icon/plus-light.svg"
              fxLayout="row"
              fxLayoutAlign="center center"
              [svgStyle]="{ 'height.px': 22, 'width.px': 22 }"
            ></svg-icon>
            <span *ngIf="!isMobile">
              {{
                config.addOptions?.label ? config.addOptions?.label : "Ajouter"
              }}
            </span>
          </div>
        </button>
      </div>
    </div>
    <!--/ Row 2 -->
  </div>
  <!--/ Header -->
  <!-- Filters Row  -->
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
    style="width: 100%"
  >
    <!-- Filters Chips -->
    <mat-chip-listbox aria-label="Filter selection" class="filters-list">
      <ng-container *ngFor="let filter of filtersList">
        <mat-chip class="mat-primary-background">
          <span class="filters-label">
            {{ filter.id + " : " + filter.label.join(", ") }}
          </span>
          <button matChipRemove (click)="removeFilter(filter)">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
      </ng-container>
    </mat-chip-listbox>
    <!-- / Filters Chips -->
  </div>
  <!--/ Filters Row -->

  <!-- Table Container -->
  <div class="table-container">
    <!-- Loader
    <div *ngIf="isLoadingResults" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px" class="loading">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
            <mat-spinner></mat-spinner>
            <i>Chargement des données</i>
        </div>
    </div>
-->
    <!-- / Loader -->

    <!-- Mat Table -->

    <mat-table
      [dataSource]="dataSource"
      *ngIf="columns"
      matSort
      class="custom-table"
      [hidden]="isLoadingResults"
      multiTemplateDataRows
    >
      <!-- Dynamic column definition using ngFor -->
      <ng-container
        *ngFor="let column of columns"
        [matColumnDef]="column.columnDef"
      >
        <!-- Mat Header -->
        <mat-header-cell
          *matHeaderCellDef
          [ngSwitch]="column.columnDef"
          [mat-sort-header]="
            column.columnDef == 'select' ? null : column.columnDef
          "
          [ngClass]="{
            'mini-col':
              column.type == 'action' &&
              ((config.editOptions?.active && config.deleteOptions?.active) ||
                (config.customActions && config.customActions.length >= 0))
          }"
        >
          <!-- 1. Select Header -->
          <ng-container *ngSwitchCase="'select'">
            <mat-checkbox
              (change)="$event ? toggleAllRows() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [color]="'primary'"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox>
          </ng-container>
          <!-- / Select Header -->

          <!-- 2. Default Header-->
          <ng-container *ngSwitchDefault>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
              <svg-icon
                *ngIf="column.iconHeader"
                [src]="'assets/icon/' + column.iconHeader + '.svg'"
                fxLayout="row"
                fxLayoutAlign="center center"
                [svgStyle]="{ 'height.px': 22, 'width.px': 22 }"
              ></svg-icon>
              <span>{{ column.header }}</span>
            </div>
          </ng-container>
          <!-- / Default Header -->
        </mat-header-cell>
        <!-- / Mat Header -->

        <!-- Mat Cell -->
        <mat-cell
          *matCellDef="let row"
          class="field-type"
          [ngClass]="{
            'field-editing': row.isEdit,
            'mini-col':
              column.type == 'action' &&
              ((config.editOptions?.active && config.deleteOptions?.active) ||
                (config.customActions && config.customActions.length >= 0))
          }"
          [hidden]="isLoadingResults"
          (click)="redirectToRow(row, column.type)"
        >
          <!-- Show View -->
          <ng-container [ngSwitch]="column.type" *ngIf="!row.isEdit">
            <!-- 1. Select Type -->
            <ng-container *ngSwitchCase="'selectRow'">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)"
                [aria-label]="checkboxLabel(row)"
                [color]="'primary'"
              >
              </mat-checkbox>
            </ng-container>
            <!-- / Select Type -->

            <!-- 2. Date Type -->
            <ng-container *ngSwitchCase="'date'">
              <span [ngClass]="getClassDynamically(column, row)">
                {{
                  column.cell(row) !== null && column.cell(row) !== "null"
                    ? (column.cell(row) | date : "dd/MM/yyyy")
                    : ""
                }}
              </span>
            </ng-container>
            <!-- / Date Type -->

            <!-- 2. DateTime Type -->
            <ng-container *ngSwitchCase="'datetime'">
              {{
                column.cell(row) !== null && column.cell(row) !== "null"
                  ? (column.cell(row) | date : "dd/MM/yyyy à HH:mm")
                  : ""
              }}
            </ng-container>
            <!-- / DateTime Type -->

            <!-- 3. Time Type -->
            <ng-container *ngSwitchCase="'time'">
              {{
                column.cell(row) !== null && column.cell(row) !== "null"
                  ? (column.cell(row) | date : "shortTime")
                  : ""
              }}
            </ng-container>
            <!-- / Time Type -->

            <!-- 4. Boolean Type -->
            <ng-container *ngSwitchCase="'boolean'">
              {{ column.cell(row) }}
            </ng-container>
            <!-- / Boolean Type -->

            <!-- 5. Boolean Chip Type -->
            <ng-container *ngSwitchCase="'boolean-chip'">
              <div
                class="boolean-chip-field"
                fxLayout="row"
                fxLayoutAlign="center center"
                fxLayoutGap="5px"
                [ngClass]="{
                  'true-chip': column.cell(row) == 'true',
                  'false-chip': column.cell(row) == 'false'
                }"
              >
                <div class="circle"></div>
                <span>{{ column.cell(row) | titlecase }}</span>
              </div>
            </ng-container>
            <!-- Revert Version -->
            <ng-container *ngSwitchCase="'boolean-chip-revert'">
              <div
                class="boolean-chip-field"
                fxLayout="row"
                fxLayoutAlign="center center"
                fxLayoutGap="5px"
                [ngClass]="{
                  'true-chip': column.cell(row) == 'false',
                  'false-chip': column.cell(row) == 'true'
                }"
              >
                <div class="circle"></div>
                <span>{{
                  (column.cell(row) === "true" ? "false" : "true") | titlecase
                }}</span>
              </div>
            </ng-container>
            <!-- Badge Version -->
            <ng-container *ngSwitchCase="'boolean-badge'">
              <div
                class="boolean-chip-field boolean-badge"
                fxLayout="row"
                fxLayoutAlign="center center"
                fxLayoutGap="5px"
                [ngClass]="
                  row[column.columnDef] == 'true' ||
                  row[column.columnDef] == true
                    ? ['mat-green-color', 'mat-green-background-pastel']
                    : row[column.columnDef] == 'false' ||
                      row[column.columnDef] == false
                    ? ['mat-warn-color', 'mat-warn-background-pastel']
                    : ['mat-grey-color', 'mat-grey-background-light-pastel']
                "
              >
                <span>{{
                  column.cell(row) === "true"
                    ? "Oui"
                    : column.cell(row) === ("false" || null)
                    ? "Non"
                    : column.cell(row)
                }}</span>
              </div>
            </ng-container>
            <!-- / Boolean Chip Type -->

            <!-- 6. Icon  -->
            <ng-container *ngSwitchCase="'icon'">
              <svg-icon
                *ngIf="column.cell(row)"
                class="icon-chip"
                [src]="'assets/icon/' + column.cell(row) + '.svg'"
                fxLayout="row"
                fxLayoutAlign="center center"
                [svgStyle]="{ 'height.px': 24, 'width.px': 24 }"
              ></svg-icon>
            </ng-container>
            <!-- / Icon  -->

            <!-- Icon Chip Type -->
            <ng-container *ngSwitchCase="'icon-chip'">
              <mat-chip-listbox class="icon-chip-field">
                <mat-chip
                  *ngFor="let item of column.cell(row)"
                  class="test"
                  [ngClass]="{
                    'valid-chip': item.value == 'valid',
                    'invalid-chip': item.value == 'invalid'
                  }"
                >
                  <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="5px"
                  >
                    <svg-icon
                      *ngIf="item.icon"
                      class="icon-chip"
                      [src]="'assets/icon/' + item.icon + '.svg'"
                      fxLayout="row"
                      fxLayoutAlign="center center"
                    ></svg-icon>
                    <span *ngIf="item.label">{{ item.label | titlecase }}</span>
                  </div>
                </mat-chip>
              </mat-chip-listbox>
            </ng-container>

            <!-- / Icon Chip Type -->

            <!-- 7. Array Type -->
            <ng-container *ngSwitchCase="'array'">
              <div *ngFor="let item of column.cell(row)">
                {{ item | json }}
              </div>
            </ng-container>
            <!-- / Array Type -->

            <!-- 8. File Type -->
            <ng-container *ngSwitchCase="'file'">
              <div
                class="img-field-wrapper"
                *ngIf="column.cell(row) !== null && column.cell(row) !== 'null'"
                [ngClass]="{ 'avatar-cell': column.columnDef == 'avatar' }"
              >
                <img
                  [src]="getFileSrc(column.cell(row))"
                  crossorigin="anonymous"
                  alt=""
                  class="img-field"
                />
              </div>
            </ng-container>
            <!-- / File Type -->

            <!-- 9. Actions Type -->
            <ng-container *ngSwitchCase="'action'">
              <!-- If One Element Show Button With Icon Only -->
              <ng-container
                *ngIf="
                  (!config.customActions || config.customActions.length == 0) &&
                    !(
                      config.editOptions?.active && config.deleteOptions?.active
                    );
                  else elseActionType
                "
              >
                <!-- Edit -->
                <button
                  mat-button
                  [disableRipple]="true"
                  (click)="editRow(row)"
                  *ngIf="config.editOptions?.active"
                  style="margin-left: auto; margin-right: auto"
                  class="edit-btn"
                >
                  <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="10px"
                    style="padding: 0px 5px"
                  >
                    <svg-icon
                      [src]="
                        'assets/icon/' +
                        (config.editOptions?.icon
                          ? config.editOptions?.icon
                          : 'edit-square-light') +
                        '.svg'
                      "
                      fxLayout="row"
                      fxLayoutAlign="center center"
                    ></svg-icon>
                    <span *ngIf="config.editOptions?.label">{{
                      config.editOptions?.label
                    }}</span>
                  </div>
                </button>
                <button
                  mat-button
                  [disableRipple]="true"
                  (click)="editRow(row)"
                  *ngIf="config.deleteOptions?.active"
                  style="margin-left: auto; margin-right: auto"
                  [ngClass]="{}"
                >
                  <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="10px"
                    style="padding: 0px 5px"
                  >
                    <svg-icon
                      [src]="
                        'assets/icon/' +
                        (config.deleteOptions?.icon
                          ? config.deleteOptions?.icon
                          : 'delete-light') +
                        '.svg'
                      "
                      fxLayout="row"
                      fxLayoutAlign="center center"
                    ></svg-icon>
                    <span *ngIf="config.deleteOptions?.label">{{
                      config.deleteOptions?.label
                    }}</span>
                  </div>
                </button>
              </ng-container>
              <!--/ Edit -->

              <!-- Else Show Menu -->
              <ng-template #elseActionType>
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  aria-label="Example icon-button with a menu"
                  class="ml-auto"
                  style="z-index: 99"
                  (click)="clickOnMenuButton = true"
                  (mouseleave)="clickOnMenuButton = false"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu #menu="matMenu">
                  <!-- Custom Action -->
                  <ng-container *ngIf="column.customActions">
                    <button
                      *ngFor="let customButton of config.customActions"
                      mat-menu-item
                      (click)="customActionRow(row, customButton)"
                      [ngClass]="{
                        'mat-warn-color': customButton.color == 'warn'
                      }"
                    >
                      <div
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        fxLayoutGap="10px"
                      >
                        <svg-icon
                          *ngIf="customButton.icon"
                          [src]="
                            'assets/icon/' +
                            customButton.icon(row[customButton.value]) +
                            '.svg'
                          "
                          fxLayout="row"
                          fxLayoutAlign="center center"
                        ></svg-icon>
                        <span
                          >{{ customButton.label(row[customButton.value]) }}
                        </span>
                      </div>
                    </button>
                  </ng-container>
                  <!--/ Custom Action -->

                  <!-- Edit -->
                  <button
                    mat-menu-item
                    (click)="editRow(row)"
                    *ngIf="config.editOptions?.active"
                  >
                    <div
                      fxLayout="row"
                      fxLayoutAlign="start center"
                      fxLayoutGap="10px"
                    >
                      <svg-icon
                        [src]="
                          'assets/icon/' +
                          (config.editOptions?.icon
                            ? config.editOptions?.icon
                            : 'edit-square-light') +
                          '.svg'
                        "
                        fxLayout="row"
                        fxLayoutAlign="center center"
                      ></svg-icon>
                      <span
                        >{{
                          config.editOptions?.label
                            ? config.editOptions?.label
                            : "Modifier"
                        }}
                      </span>
                    </div>
                  </button>
                  <!--/ Edit -->

                  <!-- Delete -->
                  <button
                    mat-menu-item
                    (click)="removeRow(row.id)"
                    *ngIf="config.deleteOptions?.active"
                  >
                    <div
                      fxLayout="row"
                      fxLayoutAlign="start center"
                      fxLayoutGap="10px"
                      class="mat-warn-color"
                    >
                      <svg-icon
                        [src]="
                          'assets/icon/' +
                          (config.deleteOptions?.icon
                            ? config.deleteOptions?.icon
                            : 'delete-light') +
                          '.svg'
                        "
                        fxLayout="row"
                        fxLayoutAlign="center center"
                      ></svg-icon>
                      <span>
                        {{
                          config.deleteOptions?.label
                            ? config.deleteOptions?.label
                            : "Supprimer"
                        }}</span
                      >
                    </div>
                  </button>
                  <!--/ Delete -->
                </mat-menu>
              </ng-template>
            </ng-container>
            <!--/ Actions Type -->

            <!-- 10. Progress Bar Type -->
            <ng-container *ngSwitchCase="'progress-bar'">
              <!-- 
            ### Note for developers ###
            The maximum is set to 100 by default, the value must be calculated as a percentage in the component configuration.
             -->
              <mat-progress-bar
                [color]="column.cell(row)['color'] ?? 'primary'"
                [mode]="column.cell(row)['mode'] ?? 'determinate'"
                [value]="
                  column.cell(row)['value']['percent']
                    ? column.cell(row)['value']['percent']
                    : column.cell(row)['value']
                    ? column.cell(row)['value']
                    : 0
                "
                [matTooltip]="
                  column.cell(row)['value']['percent'] ||
                  column.cell(row)['value']['percent'] == 0
                    ? column.cell(row)['value']['current'] +
                      ' sur ' +
                      column.cell(row)['value']['max']
                    : column.cell(row)['value']
                    ? column.cell(row)['value'] + '%'
                    : ''
                "
              ></mat-progress-bar>
            </ng-container>
            <!--/ Progress Bar Type -->

            <!-- 11. Default Type -->
            <ng-container *ngSwitchDefault>
              <span class="text-type">
                {{ column.cell(row) }}
              </span>
            </ng-container>
            <!-- / Default Type -->
          </ng-container>
          <!--/ Show View -->

          <!-- Editing View -->
          <div
            [ngSwitch]="column.type"
            *ngIf="row.isEdit && column.type != 'selectRow'"
            class="field-editing"
          >
            <div
              class="btn-edit"
              *ngSwitchCase="'action'"
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="5px"
            >
              <button mat-flat-button color="primary" (click)="updateRow(row)">
                Valider
              </button>
              <button mat-stroked-button (click)="cancelEditRow(row)">
                Annuler
              </button>
            </div>

            <!-- 1. Date Input -->
            <mat-form-field *ngSwitchCase="'date'" appearance="fill">
              <mat-label>{{ column.header }}</mat-label>
              <input
                matInput
                [matDatepicker]="picker"
                [(ngModel)]="row[column.columnDef]"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <!--/ Date Input -->

            <!-- 2. Boolean Input -->
            <div *ngSwitchCase="'boolean'">
              <mat-checkbox [(ngModel)]="row[column.columnDef]">{{
                row[column.columnDef] ? "True" : "False"
              }}</mat-checkbox>
            </div>
            <!--/ Boolean Input -->

            <!-- 3. File Input -->
            <div *ngSwitchCase="'file'">
              <div class="file-wrapper img-field-wrapper">
                <img
                  *ngIf="row[column.columnDef]"
                  [src]="row[column.columnDef]"
                  class="img-field"
                  crossorigin="anonymous"
                />
                <svg-icon
                  *ngIf="!row[column.columnDef]"
                  src="assets/icon/image-plus-bulk.svg"
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  [svgStyle]="{ 'height.px': 22, 'width.px': 22 }"
                ></svg-icon>
                <input
                  type="file"
                  [(ngModel)]="row[column.columnDef]"
                  (change)="onFileChange($event, row, column)"
                  class="input-file"
                />
              </div>
            </div>
            <!--/ File Input -->

            <!-- X. Default Input -->
            <mat-form-field *ngSwitchDefault appearance="fill">
              <mat-label>{{ column.header }}</mat-label>
              <input
                [type]="column.type"
                matInput
                [(ngModel)]="row[column.columnDef]"
              />
            </mat-form-field>
            <!--/ Default Input -->
          </div>
          <!--/ Editing View -->
        </mat-cell>
        <!-- / Mat Cell -->
      </ng-container>

      <!-- EXPANDED ROW -->
      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <mat-cell
          *matCellDef="let element"
          [attr.colspan]="columns.length"
          [ngClass]="{
            'mat-cell-no-padding':
              config.expandOptions && config.expandOptions.isLoading
          }"
        >
        </mat-cell>
      </ng-container>
      <!--/ EXPANDED ROW -->

      <!-- Header row definition -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

      <!-- Row definition -->
      <!-- Classic Row -->
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        [class.expanded-row]="
          config.expandOptions && config.expandOptions.active
        "
        [class.parent-expanded-row]="expandedElement === row"
        (click)="expandRow(row)"
      ></mat-row>

      <!--  (click)="
        expandedElement = expandedElement === row ? null : row; expandRow(row)
      " -->

      <!-- Expanded Row  -->

      <ng-container *ngIf="config.expandOptions && config.expandOptions.active">
        <mat-row
          *matRowDef="let row; columns: ['expandedDetail']"
          class="detail-row"
          [class.active-expanded-row]="expandedElement === row"
        ></mat-row>
      </ng-container>
      <!-- / Row definition -->

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow [hidden]="isLoadingResults">
        <td class="mat-cell mat-cell-no-data" colspan="4">
          <i>
            Désolé, mais nous n'avons trouvé aucun résultats pour votre
            recherche.</i
          >
        </td>
      </tr>
    </mat-table>
    <!--/ Mat Table -->

    <div class="loading-shade" *ngIf="isLoadingResults">
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
        <i>Chargement des données</i>
      </div>
    </div>
  </div>
  <!-- / Table Container -->

  <!--  Mat Paginator -->
  <mat-paginator
    [class.hidden-paginator]="
      !config.paginator || config.paginator.disabled || isLoadingResults
    "
    style="width: 100%"
    [pageSizeOptions]="[5, 10, 25, 100]"
    [pageSize]="config.paginator ? config.paginator.pageSize : 5"
    aria-label="Select page"
    [length]="resultsLength"
  ></mat-paginator>
  <!--/ Mat Paginator -->
</div>
