import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';


@Component({
  selector: 'formly-field-custom-toggle',
  template: `
    <mat-slide-toggle [formControl]="formControl" [formlyAttributes]="field"> <b>{{to['label']?to['label']:''}} </b></mat-slide-toggle>
  `,
  styles: [
    `
    mat-slide-toggle{
      width:100%;
      margin-bottom:20px;
    }
    b{
      margin-left:10px;
    }
     `]
})
export class CustomToggleFieldType extends FieldType<FieldTypeConfig> {

}