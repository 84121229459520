// auth.service.ts

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { AuthState } from '../store/auth.reducer';
import { AuthActions } from '../store/auth.actions';

@Injectable()
export class AuthService {

  constructor(
    private store: Store<{ auth: AuthState }>,
    private http: HttpClient,
    private cookieService: CookieService
  ) { }

  signIn(username: string, password: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/auth/signin`,
      {
        "username": username,
        "password": password
      }
    ).pipe(
      tap((data: any) => {

        //this.cookieService.set('access_token', data.access_token); // Stockez le token access dans un cookie
        // this.cookieService.set('refresh_token', data.refresh_token); // Stockez le refresh_token dans un cookie
      })
    );
  }

  signOut() {
    this.store.dispatch(AuthActions.signOut());
  }
  /*
  refreshToken(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/auth/refresh`);
  .pipe(
      tap((data: any) => {
        this.store.dispatch(AuthActions.signInSuccess({ accessToken: data.access_token, refreshToken: data.refresh_token }));
        this.cookieService.set('access_token', data.access_token);
      })
    );
  }*/


  /*
  refreshToken(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/auth/refresh`);
  .pipe(
      tap((data: any) => {
        this.store.dispatch(AuthActions.signInSuccess({ accessToken: data.access_token, refreshToken: data.refresh_token }));
        this.cookieService.set('access_token', data.access_token);
      })
    );
  }
  */


  refreshToken(): Observable<any> {


    return this.http.get(`${environment.apiUrl}/api/auth/refresh`);
    /*
    return this.http.get(`${environment.apiUrl}/api/auth/refresh`).pipe(
      tap(() => {
        console.log('tap')
        // this.router.navigate(['/dashboard']); // Redirigez vers la page souhaitée après le login réussi.
      }),
      map((data: any) => {
        console.log(data)
        this.cookieService.set('access_token', data.access_token); // Stockez le token access dans un cookie
        this.cookieService.set('refresh_token', data.refresh_token); // Stockez le refresh_token dans un cookie

        this.store.dispatch(AuthActions.refreshTokenSuccess({ accessToken: data.access_token, refreshToken: data.refresh_token }));
      }),
      catchError((error) => {
        this.store.dispatch(AuthActions.signInFailure({ error: error.error.message }));
        return error;
        //return of(AuthActions.signInFailure({ error: error.error.message }));
      })

    )*/

  }

  callRefreshToken(previousRequest?: string) {
    this.store.dispatch(AuthActions.refreshToken());
  }


  getUserProfile() {
    return this.http.get(`${environment.apiUrl}/api/auth/profile`);
  }


}
