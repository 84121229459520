import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'formly-datepicker-range-type',
  template: `
   <mat-form-field>
      <mat-label>{{ to.label }}</mat-label>
      <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="{{ to['startPlaceholder'] }}">
        <input matEndDate formControlName="end" placeholder="{{ to['endPlaceholder'] }}">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
      <mat-hint>{{ to['hint'] }}</mat-hint>
      <mat-error *ngIf="showError">Invalid date range</mat-error>
    </mat-form-field>
  `,
})
export class DateRangePickerFieldType extends FieldType {

  ngOnInit() { }

  get range() {
    return this.formControl as FormGroup;
  }


}
