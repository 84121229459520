<mat-sidenav-container>
  <!-- Sidenav -->
  <mat-sidenav
    #sidenav
    [mode]="isMobile ? 'over' : 'side'"
    [(opened)]="isSidenavOpened"
    [fixedInViewport]="true"
    [style.width]="sidenavWidth"
  >
    <div class="sidenav-content" fxLayout="column" fxLayoutAlign="start center">
      <div
        class="sidenav-content"
        fxLayout="column "
        fxLayoutAlign="space-between start"
        *ngIf="user && userRole"
      >
        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px">
          <!-- Logo -->
          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            fxLayoutGap="10px"
            class="logo"
          >
            <img
              src="assets/logo/{{ getLogoImg() }}"
              alt="logo"
              width="125px"
            />

            <button
              mat-mini-fab
              (click)="toggleMobileSidenav()"
              *ngIf="isMobile"
              color="warn"
              class="mobile-close-btn"
            >
              <mat-icon>close</mat-icon>
            </button>
            <!--  
                        <span *ngIf="sidenavWidth=='15em'" class="logo-text mat-primary-color">Oltoo</span>
                        -->
          </div>

          <!-- Links -->
          <div
            fxLayout="column"
            fxLayoutAlign="start start"
            *ngFor="let section of sidenavLinks"
            class="nav-link-block"
            fxLayoutGap="0px"
          >
            <div class="nav-link-header">{{ section.sectionName }}</div>
            <div
              fxLayout="column"
              fxLayoutAlign="start strech"
              fxLayoutGap="0px"
              *ngFor="let link of section.sectionLinks"
              class="w-100"
            >
              <ng-template [appHasRole]="getLinkRequiredRole(link)">
                <ng-container *ngIf="!link.subMenu; else subMenu">
                  <div
                    class="nav-link"
                    (click)="hideSideNavOnMobile()"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="0px"
                    [routerLink]="link.path"
                    [routerLinkActive]="['nav-link-active']"
                    [routerLinkActiveOptions]="{ exact: true }"
                  >
                    <svg-icon
                      [src]="'assets/icon/' + link.icon + '.svg'"
                      fxLayout="row"
                      fxLayoutAlign="center center"
                      [svgStyle]="{ 'height.px': 24 }"
                    ></svg-icon>
                    <span *ngIf="sidenavWidth == '15em'">{{ link.name }}</span>
                  </div>

                  <!-- Child Menu -->
                  <ng-container *ngIf="link.childMenu">
                    <div
                      *ngFor="let child of link.childMenu"
                      (click)="hideSideNavOnMobile()"
                      class="nav-link child-nav-link"
                      fxLayout="row"
                      fxLayoutAlign="start center"
                      fxLayoutGap="0px"
                      [routerLink]="child.path"
                      [routerLinkActive]="['nav-link-active']"
                      [routerLinkActiveOptions]="{ exact: true }"
                    >
                      <svg-icon
                        [src]="'assets/icon/' + child.icon + '.svg'"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        [svgStyle]="{ 'height.px': 20 }"
                      ></svg-icon>
                      <span *ngIf="sidenavWidth == '15em'">{{
                        child.name
                      }}</span>
                    </div>
                  </ng-container>
                  <!-- / Child Menu -->
                </ng-container>

                <ng-template #subMenu>
                  <mat-expansion-panel
                    class="submenu-panel mat-elevation-z0 w-100"
                    [expanded]="link.showSubmenu"
                    (expandedChange)="toggleSubmenu(link)"
                    matRipple
                    [matRippleDisabled]="true"
                  >
                    <mat-expansion-panel-header
                      class="nav-link w-100"
                      style="padding: 0px 5px 0px 10px"
                    >
                      <div
                        fxLayout="row"
                        fxLayoutAlign="space-between center"
                        fxLayoutGap="10px"
                        [routerLink]="link.path"
                        [routerLinkActive]="['nav-link-active']"
                        [routerLinkActiveOptions]="{ exact: true }"
                        class="w-100"
                      >
                        <div
                          fxLayout="row"
                          fxLayoutAlign="start center"
                          fxLayoutGap="10px"
                        >
                          <svg-icon
                            [src]="'assets/icon/' + link.icon + '.svg'"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                            [svgStyle]="{ 'height.px': 24 }"
                          ></svg-icon>
                          <span *ngIf="sidenavWidth == '15em'">{{
                            link.name
                          }}</span>
                        </div>

                        <mat-icon
                          *ngIf="link.subMenu && link.subMenu.length > 0"
                          >{{
                            link.showSubmenu ? "expand_less" : "expand_more"
                          }}</mat-icon
                        >
                      </div>
                    </mat-expansion-panel-header>
                    <div *ngIf="link.showSubmenu" class="submenu">
                      <ng-container *ngFor="let link of link.subMenu">
                        <div
                          class="nav-link"
                          (click)="hideSideNavOnMobile()"
                          fxLayout="row"
                          fxLayoutAlign="start center"
                          fxLayoutGap="0px"
                          [routerLink]="link.path"
                          [routerLinkActive]="['nav-link-active']"
                          [routerLinkActiveOptions]="{ exact: true }"
                        >
                          <svg-icon
                            [src]="'assets/icon/' + link.icon + '.svg'"
                            fxLayout="row"
                            fxLayoutAlign="center center"
                            [svgStyle]="{ 'height.px': 20 }"
                          ></svg-icon>
                          <span *ngIf="sidenavWidth == '15em'">{{
                            link.name
                          }}</span>
                        </div>
                      </ng-container>
                    </div>
                  </mat-expansion-panel>
                </ng-template>
              </ng-template>
            </div>
          </div>
        </div>

        <!-- Bottom Navbar  -->
        <div
          fxLayout="column"
          fxLayoutAlign="start start"
          fxLayoutGap="20px"
          class="nav-link-block"
        >
          <div
            fxLayout="row"
            class="nav-link"
            fxLayoutAlign="start center"
            fxLayoutGap="10px"
            (click)="signOut()"
          >
            <mat-icon>logout</mat-icon>
            <span *ngIf="sidenavWidth == '15em'">Se déconnecter</span>
          </div>
          <!-- 
                    <div fxLayout="row" class="nav-link" fxLayoutAlign="start center" fxLayoutGap="10px"
                        (click)="toggleTheme()">
                        <mat-icon>nights_stay</mat-icon>
                        <mat-slide-toggle [(ngModel)]="toggleDarkTheme"></mat-slide-toggle>
                    </div>
                    <div class="nav-link" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px"
                        (click)="toggleSidenav()">
                        <mat-icon>crop_free</mat-icon>
                        <span *ngIf="sidenavWidth=='15em'">Réduire</span>
                    </div>
                    -->
        </div>
      </div>
    </div>
  </mat-sidenav>

  <!-- Content -->
  <mat-sidenav-content
    [ngStyle]="{ marginLeft: isMobile ? '0em' : sidenavWidth }"
  >
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
      <!-- ToolBar -->
      <mat-toolbar class="search-toolbar" fxLayoutAlign="space-between center">
        <button
          mat-icon-button
          (click)="toggleMobileSidenav()"
          *ngIf="isMobile"
        >
          <mat-icon>menu</mat-icon>
        </button>
        <app-searchbar class="w-100" *ngIf="isAdmin"></app-searchbar>
        <div *ngIf="!isAdmin"></div>
        <!--  
                <mat-form-field class="search-form-field">
                    <mat-icon matPrefix>search</mat-icon>
                    <input type="text" matInput placeholder="Rechercher un élément">
                    <mat-icon matSuffix>tune</mat-icon>
                </mat-form-field>
            -->
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0px">
          <div
            *ngIf="user && user.id"
            class="user-circle"
            [routerLink]="[isAdmin ? 'admin' : 'dashboard', 'profil', user.id]"
            (click)="goToUserProfile(user.id)"
          >
            <img
              *ngIf="user && user.avatar; else defautUserAvatar"
              [src]="user.avatar"
              crossorigin="anonymous"
            />
            <ng-template #defautUserAvatar>
              <svg-icon
                src="assets/icon/user-bold.svg"
                fxLayout="row"
                fxLayoutAlign="center center"
                [svgStyle]="{ 'height.px': 28, 'width.px': 28 }"
                style="height: 40px"
              ></svg-icon>
            </ng-template>
          </div>
        </div>
      </mat-toolbar>
    </div>

    <div class="router-content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
