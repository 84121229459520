<mat-card
  class="mat-elevation-z0 offer-card"
  [ngClass]="{ 'active-card': isCardActive(data.start_date, data.end_date) }"
  *ngIf="data"
  fxLayout="row"
  fxLayoutAlign="start"
>
  <mat-card-content
    fxLayout="column"
    fxLayout.gt-xs="row"
    fxLayoutAlign="center center"
    fxLayoutAlign.gt-xs="start center"
    fxLayoutGap="20px"
  >
    <svg-icon src="assets/icon/oltoo-eyes.svg" class="icon-card"></svg-icon>

    <div
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutAlign.gt-xs="start stretch"
      fxLayoutGap="15px"
      fxLayoutGap.gt-xs="5px"
      class="content-card"
    >
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h1>{{ data.name }}</h1>

        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          class="menu-btn"
          *ngIf="isAdmin"
        >
          <svg-icon
            src="assets/icon/info-menu-light.svg"
            fxLayout="row"
            fxLayoutAlign="center center"
          ></svg-icon>
        </button>

        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="editOffer()">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <svg-icon
                src="assets/icon/edit-square-light.svg"
                fxLayout="row"
                fxLayoutAlign="center center"
              ></svg-icon>
              <span>Modifier</span>
            </div>
          </button>
          <button mat-menu-item (click)="deleteOffer()">
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="10px"
              class="mat-warn-color"
            >
              <svg-icon
                src="assets/icon/delete-light.svg"
                fxLayout="row"
                fxLayoutAlign="center center"
              ></svg-icon>
              <span>Supprimer</span>
            </div>
          </button>
        </mat-menu>
      </div>

      <h2>
        {{ data.start_date | date : "fullDate" | titlecase }}
        {{ getTimeWithoutMiliseconds(data.start_hour) }} -
        {{ data.end_date | date : "fullDate" | titlecase }}
        {{ getTimeWithoutMiliseconds(data.end_hour) }}
      </h2>

      <!-- Chip List -->
      <div
        fxLayout="row wrap"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
        class="chip-list"
      >
        <div
          class="chip-card"
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="10px"
          *ngFor="let chip of data.discounts"
        >
          <svg-icon
            [src]="'assets/icon/' + chip.icon + '.svg'"
            fxLayout="row"
            fxLayoutAlign="center center"
          ></svg-icon>
          <span class="chip-label">{{ chip.name | titlecase }}</span>
        </div>
      </div>
      <!--/ Chip List -->
    </div>
  </mat-card-content>
</mat-card>
