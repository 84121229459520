import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldType, FormlyField } from '@ngx-formly/core';

@Component({
  selector: 'formly-form-flex',
  template: `
    <div
      class="w-100" 
      [fxLayout]="to['fxLayout']"
      [fxLayoutGap]="to['fxLayoutGap']?to['fxLayoutGap']:'0px'" 
      fxLayout.xs="column"  
      fxFlexFill  
    >

      <formly-field *ngFor="let f of field.fieldGroup" [field]="f" [fxFlex]="f['props'] && f['props']['fxFlex']?f['props']['fxFlex']:'100%'">

    
      </formly-field>
    </div>
  `,
})
export class FlexLayoutType extends FieldType {



}