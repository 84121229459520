import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-payment-cancel',
  templateUrl: './payment-cancel.component.html',
  styleUrls: ['./payment-cancel.component.scss']
})
export class PaymentCancelComponent {
  establishmentId!: string;
  counter: number = 10;

  constructor(private _activatedroute: ActivatedRoute, private router: Router) { }


  ngOnInit(): void {
    this._activatedroute.params.subscribe(params => {
      this.establishmentId = params['id'];
      // Redirection Auto in 10 sec
      setInterval(() => {
        this.decrementCounter()
      }, 1 * 1000);

    });
  }

  // Redirect To Establishment
  redirectToEtab() {
    if (this.establishmentId && this.establishmentId !== '') {
      this.router.navigate(['/dashboard/etablissement/', this.establishmentId])
    }
  }

  decrementCounter() {
    this.counter--;
    if (this.counter == 0) {
      this.redirectToEtab();
    }
  }



}
