// auth.reducer.ts

import { createReducer, on } from '@ngrx/store';
import { AuthActions } from './auth.actions';

export interface AuthState {

    isLoggedIn: boolean;
    user: any; // Remplacez 'any' par le type de votre utilisateur
    accessToken: string | null;
    refreshToken: string | null;
    errorMessage: string | null;
}

export const initialState: AuthState = {
    isLoggedIn: false,
    user: null,
    accessToken: null,
    refreshToken: null,
    errorMessage: null,
};

export const authReducer = createReducer(
    initialState,
    on(AuthActions.signIn, (state) => ({ ...state })),
    on(AuthActions.signOut, (state) => ({ ...state, isLoggedIn: false, user: null, accessToken: null, refreshToken: null })),
    on(AuthActions.signInSuccess, (state, { accessToken, refreshToken }) => ({ ...state, accessToken, refreshToken, isLoggedIn: true })),
    on(AuthActions.signInFailure, (state, { error }) => ({ ...state, errorMessage: error })),
    on(AuthActions.refreshToken, (state) => ({ ...state })),
    on(AuthActions.refreshTokenSuccess, (state, { accessToken, refreshToken, user }) => ({ ...state, accessToken, refreshToken, isLoggedIn: true, user: user })),
    on(AuthActions.getUserProfileSuccess, (state, { user }) => ({ ...state, user })),
    on(AuthActions.getUserProfileFailure, (state, { error }) => ({ ...state, errorMessage: error })),
    on(AuthActions.updateUserProfile, (state, { user }) => ({ ...state, user })),
);
