import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-payment-successfull',
  templateUrl: './payment-successfull.component.html',
  styleUrls: ['./payment-successfull.component.scss']
})
export class PaymentSuccessfullComponent {
  user: any;
  establishmentId!: string;
  counter: number = 10;
  private endpoint: string = environment.apiUrl;
  constructor(private _activatedroute: ActivatedRoute, private router: Router, private http: HttpClient) { }


  ngOnInit(): void {
    this._activatedroute.params.subscribe(params => {
      this.establishmentId = params['id'];
      this.user = JSON.parse(localStorage.getItem("user") ?? "");

      if (params['order'] && params['order'] != '') {
        this.confirmOrderPayment(params['order']).subscribe(data => console.log(data))
      }

      // Redirection Auto in 10 sec
      setInterval(() => {
        this.decrementCounter()
      }, 1 * 1000);

    });
  }

  // Redirect To Establishment
  redirectToEtab() {
    if (this.establishmentId && this.establishmentId !== '') {
      this.router.navigate(['/dashboard/etablissement/', this.establishmentId])
    }
  }

  decrementCounter() {
    this.counter--;
    if (this.counter == 0) {
      this.redirectToEtab();
    }
  }

  confirmOrderPayment(id: string): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/api/promoted/confirm-order-payment/${id}`, {});
  }



}
