import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FieldType } from '@ngx-formly/core';
import { CdkDragDrop, CdkDrag, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'formly-field-file',
  template: `
        <div>
            <label>{{ to.label }}</label>
 
            <div class="drag-and-drop example-list"  appDnd (fileDropped)="onFileDropped($event)" cdkDropList cdkDropListOrientation="horizontal"  (cdkDropListDropped)="drop($event)">
                 <input type="file" (change)="onFileSelected($event)" [accept]="to['accept']"
                [multiple]="to['multiple']" [hidden]="false" />
                <div class="flex-column">

                    <div *ngIf="files.length==0"  class="flex-column">
                        <svg-icon  src="assets/img/file-dialog.svg" fxLayout="row"
                        fxLayoutAlign="center center" class="mat-primary-color"></svg-icon>
                        <div>
                            <h4 class="mat-primary-color file-title" >Glisser-déposer des images à télécharger</h4>
                            <span class="text-black">( Taille maximale d’une photo 5MB )</span>
                        </div>
                        <h4 class="file-title text-black"> OU </h4>
                         <div  class="select-btn mat-primary-background">
                          Chercher un fichier
                        </div>
                    </div>

                    <div class="flex-row-wrap" >
                      <ng-container  *ngFor="let image of files; let i = index">
                        <div *ngIf="!image.state" class="image-preview" cdkDrag>
                          <img [src]="image.url" alt="Image prévisualisée"  crossorigin="anonymous" />
                          <button mat-mini-fab color="primary" (click)="removeImage(i)">
                            <svg-icon src="assets/icon/delete-light.svg" fxLayout="row" fxLayoutAlign="center center"[svgStyle]="{'height.px':24}"></svg-icon>
                          </button>
                        </div>
                      </ng-container>
                    </div>
                    
                </div>
            </div>
        </div>
  `,
  styles: [
    `
      .drag-and-drop {
        position:relative;
        display:flex;
        align-items:center;
        justify-content:center;
        border: 2px dashed #E0E0E0;
        padding: 20px;
        min-height:200px;
        text-align: center;
        border-radius:5px;
        cursor:pointer;
      }

      .flex-row{
          display:flex;
          justify-content:center;
          align-items:center;
          gap:10px;
      }

      .flex-column{
        display:flex;
        flex-direction:column;
        align-items:center;
        gap:10px;
      }

      .flex-row-wrap{
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap:25px;
      }

      .file-title{
        font-size:24px;
        font-weight: 700;
        margin-bottom:5px;
      }
      .text-black{
        color:black;
      }
      .select-btn{
        width:150px;
        border-radius: 200px;
        padding: 12px 24px;
        color: white;
      }

      input{
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        opacity:0;
        cursor:pointer;
      }

      .image-preview {
        display: inline-block;
        margin-top: 10px;
        position: relative;

        button{
            position: absolute;
            right: -12px;
            top: -12px;
        }
      }

      img {
        width: 150px;
        height: 150px;
        margin-right: 10px;
        object-fit: cover;
      }

      
    `,
  ],
})
export class FormlyFieldFileDragNDrop extends FieldType {

  files: any[] = [];

  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  ngOnInit() {
    // Set the default value for the form control
    if (this.field.formControl?.value.length > 0) {
      this.field.formControl?.value.forEach((img: any) => {
        this.files.push({ url: img })
      })
    }
  }

  onFileSelected(event: Event) {
    const files: any = (event.target as HTMLInputElement).files;
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(files[i]));
        files[i]['url'] = url
        this.files.push(files[i]);
      }
    }
    this.field.formControl?.setValue(this.files); // Mettez à jour le tableau d'images dans le FormControl
  }

  /**
   * On file drop handler
   */
  onFileDropped($event: any[]) {
    if (this.field.props && this.field.props['multiple']) {
      this.prepareFilesList($event);
    } else {
      this.prepareFilesList($event.length > 1 ? [$event[0]] : $event);
    }
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      this.files.push(item);
    }
    this.field.formControl?.setValue(files)
  }

  getFormControl(): FormControl {
    return this.field.formControl as FormControl;
  }

  removeImage(index: number) {
    const saveUrl = { ...this.files[index] }['url']
    if (this.field.formControl) {
      this.files[index] = { state: "deleted", url: saveUrl },
        this.field.formControl.value[index] = { state: "deleted", url: saveUrl }
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.files, event.previousIndex, event.currentIndex);
    this.field.formControl?.setValue(this.files)
  }

}