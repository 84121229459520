import { Component } from '@angular/core';
import { ApiService } from './service/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss']
})
export class SearchbarComponent {
  searchText: string = "";
  showResults = false;
  searchResults: any = {}
  apiEndpointList: any = {
    establishments: {
      key: 'establishments', label: 'Établissements', icon: 'building-office-single-bulk', path: 'dashboard/etablissement/',
      titleCell: (element: any) => `${element.name}`
    },
    users: {
      key: 'users', label: 'Utilisateurs', icon: '3-user-bulk', path: 'admin/user/',
      titleCell: (element: any) => `${element.first_name + " " + element.last_name}`,
      lineCell: (element: any) => `${element.email}`,
    }
  }

  constructor(private api: ApiService, private router: Router) { }

  applyFilter(event: Event) {
    this.searchText = (event.target as HTMLInputElement).value;
    if (this.searchText.length > 2) {
      this.showResults = true;
      Object.keys(this.apiEndpointList).forEach((endpoint: any) => {
        this.api.getAPIGlobalPaginated(endpoint, 0, 10, this.searchText).subscribe(data => {
          this.searchResults[endpoint] = data.data;
        });
      });
    } else {
      this.searchResults = {};
      this.showResults = false;
    }
  }
  resetSearchText() {
    this.searchText = "";
    this.searchResults = {};
    this.showResults = false;
  }


  getResultsToShow() {
    return Object.keys(this.searchResults)
  }

  getHeaderLabel(key: string) {
    return this.apiEndpointList[key].label
  }

  // RouterLink 
  goToLink(key: string, id: string) {
    this.router.navigate([this.apiEndpointList[key].path, id])
  }

}
