import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from '../store/app.state';
import { isLoggedIn, selectLoggedUser } from '../store/auth.selectors';
import { User } from '@data/models/user.model';


@Injectable()
export class AuthGuard {

  constructor(private store: Store<AppState>, private route: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.pipe(
      select(isLoggedIn),
      tap(isLogged => {

        // Not Logged
        if (!isLogged) {
          this.route.navigateByUrl('/login');
          return false;
        } else {
          return true;
        }

      })
    )
  }

}

@Injectable()
export class AdminGuard {

  constructor(private store: Store<AppState>, private route: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.pipe(
      select(selectLoggedUser),
      tap(user => {

        const loggedUser: User = typeof user == 'string' ? JSON.parse(user) : user;

        if (loggedUser.scopes && loggedUser.scopes.length > 0) {
          let rolesList = new Set();
          const requiredRoles = route.data['requiredRoles'];
          // Add Each Role to the RoleList Set
          loggedUser.scopes.forEach((scope: any) => {
            rolesList.add(scope.role.name)
          });

          // If Contains same role in Set and requiredRoles allow to show navLink
          if (requiredRoles.length == 0 || Array.from(rolesList).some((role: any) => requiredRoles.includes(role))) {
            return true;
          } else {
            alert('You are not allowed to view this page');
            this.route.navigate(['']);
            return false;
          }

        }
        return false;
      }

      ));
  }


}
