import { Component, OnInit } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { map } from 'rxjs/operators';

import { themes } from 'src/app/core/constants/themes';
import { ThemeService } from 'src/app/core/service/theme.service';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss']
})
export class ContentLayoutComponent implements OnInit {
  currentTheme!: string;

  currentActiveTheme$ = this.themeService.getIsDarkTheme().pipe(
    map((isDarkTheme: boolean) => {
      const [lightTheme, darkTheme] = themes;
      this.currentTheme = isDarkTheme ? darkTheme.name : lightTheme.name;

      // Overlay Theming
      const overlayContainerClasses = this._overlayContainer.getContainerElement().classList;
      const themeClassesToRemove = Array.from(overlayContainerClasses)
        .filter((item: string) => item.includes('theme'));
      if (themeClassesToRemove.length) {
        overlayContainerClasses.remove(...themeClassesToRemove);
      }
      overlayContainerClasses.add(this.currentTheme);

      return this.currentTheme;
    })
  );

  constructor(private themeService: ThemeService, private _overlayContainer: OverlayContainer) { }

  ngOnInit(): void { }

}
