<p>filter-card works!</p>
<div class="card-border filter-modal" fxLayout="column" fxLayoutAlign="start strech" fxLayoutGap="0px">

    <!-- Filter Header -->
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
        <h4 class="filter-modal-title">Filtres</h4>
        <button mat-icon-button>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <svg-icon src="assets/icon/close-square-light.svg" fxLayout="row"
                    fxLayoutAlign="center center"></svg-icon>
            </div>
        </button>
    </div>
    <mat-divider></mat-divider>

</div>