<ng-container *ngIf="data.config">





    <mat-dialog-content class="mat-typography" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px"
        *ngIf="data.config.content">

        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">

            <img *ngIf="data.config.content.img" [src]="'assets/img/'+data.config.content.img" fxLayout="row"
                fxLayoutAlign="center center" class="mat-primary-color" width="100px">

            <h3 class="mat-primary-color dialog-title" *ngIf="data.config.content.title">{{data.config.content.title}}
            </h3>

            <div *ngIf="data.config.content.text" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="0px">
                <ng-container *ngFor="let item of data.config.content.text">
                    <p class="dialog-text">{{item}}</p>
                </ng-container>
            </div>

        </div>

        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" class="dialog-actions">

            <button mat-flat-button color="primary" (click)="submitDialogForm()">{{data.config.actions.submit ?
                data.config.actions.submit
                :'Fermer'}}</button>
        </div>

    </mat-dialog-content>

</ng-container>