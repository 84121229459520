import { Component } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { transition, animate, trigger, state, style, query } from '@angular/animations';
import { ThemeService } from 'src/app/core/service/theme.service';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { AppState } from '@app/core/store/app.state';
import { AuthActions } from '@app/core/store/auth.actions';
import { CookieService } from 'ngx-cookie-service';
import { selectLoggedUser } from '@core/store/auth.selectors';
import { Role } from '@data/models/role.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';


interface NavBarSection {
  sectionName: string,
  divider: boolean,
  sectionLinks: Array<NavBarLink>
}

interface NavBarLink {
  name: string,
  icon: string,
  path: string,
  showSubmenu: boolean,
  childMenu?: Array<any>,
  subMenu?: Array<any>,
  requiredRoles?: Array<any>,
  chips?: []
}


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  toggleDarkTheme = false;
  public Role = Role;
  isSidenavOpened = true;
  sidenavWidth = '15em';
  user$: Observable<any> = this._store.select(selectLoggedUser)
  hasEstablishment: any = false;
  hasEstablishmentChain: any = false;
  private endpoint: string = environment.apiUrl;
  newsList: any = [];
  isAdmin = false;
  public isMobile = false;

  constructor(private themeService: ThemeService, private _store: Store<AppState>, private router: Router, private cookieService: CookieService, private http: HttpClient) { }

  ngOnInit() {
    // this.themeService.getIsDarkTheme().subscribe(event => { console.log(event); this.toggleDarkTheme = event });
    // Check if user is on a mobile device
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      // true for mobile device
      this.isMobile = true;
      this.sidenavWidth = '0em';
    } else {
      this.isMobile = false;
    }
    this.geLogInUser();
  }

  toggleTheme() {
    this.themeService.setDarkTheme(this.toggleDarkTheme)
  }

  toggleSidenav() {
    this.sidenavWidth = this.sidenavWidth == '6em' ? '15em' : '6em';
  }
  toggleMobileSidenav() {
    this.sidenavWidth = this.sidenavWidth == '0em' ? '15em' : '0em';
  }

  signOut() {
    this.cookieService.deleteAll();
    this._store.dispatch(AuthActions.signOut());
  }

  user!: any;
  userRole = new Set();
  geLogInUser() {
    this.user$.subscribe(data => {

      if (data == undefined) {
        setTimeout(() => {
          data = localStorage.getItem('user');
          this.user = (typeof data == 'string' ? JSON.parse(data) : data);

          // On 
          if (this.sidenavLinks.length <= 1) {
            this.user.scopes.forEach((scope: any) => {
              this.userRole.add(this.getUserRoles(scope))
            });
          }
          // this.userRole = this.getUserRoles(this.user.scopes[0] ?? {})
        }, 1000);
      }
      else if (data) {
        this.user = (typeof data == 'string' ? JSON.parse(data) : data);
        if (this.sidenavLinks.length <= 1) {
          this.user.scopes.forEach((scope: any) => {
            this.userRole.add(this.getUserRoles(scope))
          });
        }
        //  this.userRole = this.getUserRoles(this.user.scopes[0] ?? {})
      }

    });
  }

  getUserRoles(scope: any) {
    if (scope) {

      // Update Variables;
      this.hasEstablishmentChain = scope.establishment_chain ? true : false;
      this.hasEstablishment = scope.establishment ? true : false;

      // Step 1 : Check if subMenu " list estab already exist"
      var listEstabExist = this.sidenavLinks.findIndex(item => item.sectionName === "AUTRES ETABLISSEMENTS");
      if (listEstabExist == -1 && this.hasEstablishment) {
        const navLinkSubPart: any = {
          sectionName: 'AUTRES ETABLISSEMENTS',
          divider: true,
          sectionLinks: []
        }
        this.sidenavLinks.push(navLinkSubPart);
        listEstabExist = this.sidenavLinks.findIndex(item => item.sectionName === "AUTRES ETABLISSEMENTS");
      }

      // Step 2 : Check if is an instance of establishment_chain
      if (this.hasEstablishmentChain) {
        this.addEstablishmentChainToNav(scope, listEstabExist);
      }

      // Step 3 : Check if is an instance of establishment
      if (this.hasEstablishment) {
        console.log("ESTAB");
        this.addEstablishmentToNav(scope, listEstabExist);
      }
      if (scope.role.name == "root" || scope.role.name == "admin") {
        this.isAdmin = true;
        this.addAdminToNav();
      }
    }
    return scope.role.name
  }


  addEstablishmentChainToNav(scope: any, index: number) {

    // Check if global establishmentChainLink exist
    var establishmentChainLinkExist = this.sidenavLinks.findIndex(item => item.sectionName === "ENSEIGNES");
    if (establishmentChainLinkExist == -1) {
      const navLinkSubPart: any = {
        sectionName: 'ENSEIGNES',
        divider: true,
        sectionLinks: []
      }
      this.sidenavLinks.splice(1, 0, navLinkSubPart)

      establishmentChainLinkExist = this.sidenavLinks.findIndex(item => item.sectionName === "ENSEIGNES");
      index = this.sidenavLinks.findIndex(item => item.sectionName === "AUTRES ETABLISSEMENTS");
      const establishmentChainLink: any = {
        name: scope.establishment_chain.name,
        icon: 'building-light',
        path: ['dashboard/enseigne', scope.establishment_chain.id],
        requiredRoles: [Role.Etablishment],
        showSubmenu: false,
        childMenu: [],
        chips: []
      }
      this.sidenavLinks[establishmentChainLinkExist].sectionLinks.push(establishmentChainLink)

    }

    // For Each Establishement push it into Establishement of Chain
    this.getEstablishementofChain(scope.establishment_chain.id).subscribe(data => {
      if (data.establishments) {
        const indexEstablishmentChain = this.sidenavLinks[establishmentChainLinkExist].sectionLinks.findIndex(item => item.name === scope.establishment_chain.name);

        data.establishments.forEach((etab: any) => {

          const establishmentNavLink: any = {
            name: etab.name,
            icon: 'building-office-single-light',
            path: 'dashboard/etablissement/' + etab.id,
            showSubmenu: false,
            requiredRoles: [Role.Etablishment],
            chips: []
          }

          if (indexEstablishmentChain != -1) {
            this.sidenavLinks[establishmentChainLinkExist].sectionLinks[indexEstablishmentChain].childMenu?.push(establishmentNavLink);
          }

        });

      }
    });

  }


  addEstablishmentToNav(scope: any, index: number) {
    const globalMenuExist = this.sidenavLinks.findIndex(menu => menu.sectionName == 'MENU');
    if (globalMenuExist == -1) {
      this.sidenavLinks.unshift(
        {
          sectionName: 'MENU',
          divider: true,
          sectionLinks: [
            {
              name: 'Accueil',
              icon: 'home-light',
              path: 'dashboard/home',
              showSubmenu: false,
              childMenu: [],
              chips: [], requiredRoles: [Role.Etablishment],
            },
          ]
        }
      )
    }

    const establishmentNavLink: any = {
      name: scope.establishment.name,
      icon: 'building-office-single-light',
      path: 'dashboard/etablissement/' + scope.establishment.id,
      showSubmenu: false,
      requiredRoles: [Role.Etablishment],
      chips: []
    }
    this.sidenavLinks[index].sectionLinks.push(establishmentNavLink)
  }

  addAdminToNav() {
    this.sidenavLinks.splice(1, 0, {
      sectionName: 'ADMIN',
      divider: true,
      sectionLinks: [
        {
          name: 'Accueil',
          icon: 'home-light',
          path: 'admin/home',
          requiredRoles: [Role.Admin, Role.Root],
          showSubmenu: false,
        },
        {
          name: "Enseignes",
          icon: 'building-light',
          path: 'admin/establishments-chain',
          requiredRoles: [Role.Admin, Role.Root],
          showSubmenu: false,
        },
        {
          name: 'Etablissements',
          icon: 'building-office-single-light',
          path: 'admin/establishments',
          requiredRoles: [Role.Admin, Role.Root],
          showSubmenu: false,
        },
        {
          name: 'Offres',
          icon: 'ticket-light',
          path: 'admin/offers',
          requiredRoles: [Role.Admin, Role.Root],
          showSubmenu: false,
        },
        {
          name: 'Utilisateurs',
          icon: '3-user-light',
          path: 'admin/users',
          requiredRoles: [Role.Admin, Role.Root],
          showSubmenu: false,
        },
        {
          name: 'Abonnements',
          icon: 'bank-card-light',
          path: 'admin/subscriptions',
          requiredRoles: [Role.Admin, Role.Root],
          showSubmenu: false,
        },
        {
          name: 'Semaine',
          icon: '7-day-return-guarantee-light',
          path: 'admin/establishments-week',
          requiredRoles: [Role.Admin, Role.Root],
          showSubmenu: false,
        },
        {
          name: 'Annonces',
          icon: 'speaker-megaphone-light',
          path: 'admin/announcements',
          requiredRoles: [Role.Admin, Role.Root],
          showSubmenu: false,
        },
        {
          name: 'Concours',
          icon: 'gift-light',
          path: 'admin/competitions',
          requiredRoles: [Role.Admin, Role.Root],
          showSubmenu: false,
        },
        {
          name: 'Campagnes',
          icon: 'folder-file-hashtag-light',
          path: 'admin/campaigns',
          requiredRoles: [Role.Admin, Role.Root],
          showSubmenu: false,
        },
        {
          name: 'Classement',
          icon: 'rank-star-light',
          path: 'admin/classement',
          requiredRoles: [Role.Admin, Role.Root],
          showSubmenu: false,
        },
        {
          name: 'Compteur de vues',
          icon: 'show-light',
          path: 'admin/counter',
          requiredRoles: [Role.Admin, Role.Root],
          showSubmenu: false,
        }
      ],
    })
  }

  getLogoImg() {
    let img = 'oltoo-logo.png'
    // this.isDarkTheme$.subscribe(valueTheme => img = valueTheme ? 'whiteLogo.png' : 'logo.png')
    return img;
  }

  goToUserProfile(userId: String) {

    // this.router.navigate("")

  }

  sidenavLinks: Array<NavBarSection> = [];

  toggleSubmenu(section: any) {
    section.showSubmenu = !section.showSubmenu;
  }

  getLinkRequiredRole(link: any) {
    return link.requiredRoles ? link.requiredRoles : [];
  }

  getEstablishementofChain(idChain: string): Observable<any> {
    return this.http.get<any>(`${this.endpoint}/api/establishment-chain/${idChain}`);
  }

  getNews(): Observable<any> {
    return this.http.get<any>(`${this.endpoint}/api/news`);
  }

  hideSideNavOnMobile() {
    if (this.isMobile) {
      this.sidenavWidth = '0em';
    }
  }

  /* ------------------------ FOR DEBUG ----------------------- */

  getCondition(label: string) {
    switch (label) {
      case 'etablissement':
        return this.hasEstablishment;
        break;
      case 'etablissementChain':
        return this.hasEstablishmentChain;
        break;

      default:
        break;
    }
  }




  /* ------------------------ /  FOR DEBUG ----------------------- */
}
