import { Component } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { themes } from '@core/constants/themes';
import { ThemeService } from '@core/service/theme.service';
// Config
import authConfig from '@data/config/auth.config.json';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent {
  currentTheme!: string;
  authConfig = authConfig; // Auth Config From JsonFile
  currentActiveTheme$ = this.themeService.getIsDarkTheme().pipe(
    map((isDarkTheme: boolean) => {
      const [lightTheme, darkTheme] = themes;
      this.currentTheme = isDarkTheme ? darkTheme.name : lightTheme.name;
      return this.currentTheme;
    })
  );

  private overlayContainer!: OverlayContainer;
  constructor(private themeService: ThemeService, protected router: Router) { }

  ngOnInit(): void {

    if (this.overlayContainer) {
      this.overlayContainer
        .getContainerElement()
        .classList.add(this.currentTheme);
    }

  }
}
