// auth.selectors.ts

import { createSelector } from '@ngrx/store';
import { AppState } from './app.state';

// Sélecteur pour récupérer le token access à partir du Store
export const selectAuthState = (state: AppState) => state.auth;

export const selectAccessToken = createSelector(
    selectAuthState,
    (authState) => authState.accessToken
);

export const isLoggedIn = createSelector(
    selectAuthState,
    (authState) => authState.isLoggedIn
);


export const selectLoggedUser = createSelector(
    selectAuthState,
    (authState) => authState.user
);

// Sélecteur pour obtenir la propriété errorMessage à partir de l'état d'authentification
export const selectErrorMessage = createSelector(
    selectAuthState,
    (state) => state.errorMessage
);