import { Component, Directive, Inject, Injectable, Input, SimpleChanges } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateRange, DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY, MAT_RANGE_DATE_SELECTION_MODEL_PROVIDER, MatCalendarCellCssClasses, MatDateRangeSelectionStrategy, MatRangeDateSelectionModel } from '@angular/material/datepicker';
import { DateAdapter } from "@angular/material/core";

/*  --------------------------------  MAX RANGE STRATEGY --------------------------------  */
// https://stackblitz.com/edit/angular-hzkcmw?file=src%2Fapp%2Fdate-range-picker-selection-strategy-example.ts,src%2Fapp%2Fdate-range-picker-selection-strategy-example.html

@Injectable()
export class MaxRangeSelectionStrategy<D>
    implements MatDateRangeSelectionStrategy<D> {
    start: any;
    public delta!: number;
    dateNotAvaible = [];
    countDaysNotAvaibleBetweenRange = 0;
    constructor(private _dateAdapter: DateAdapter<D>) { }

    selectionFinished(date: D, currentRange: DateRange<D>) {
        let { start, end } = currentRange;

        if (start == null || (start && end)) {
            start = date;
            end = null;
        } else if (end == null) {
            const maxDate = this._dateAdapter.addCalendarDays(start, this.delta + this.countDaysNotAvaibleBetweenRange);
            end = date ? (date > maxDate ? maxDate : date) : null;
        }
        if (this.delta == 0) {
            return new DateRange<D>(null, null);
        }

        return new DateRange<D>(start, end);
    }

    createPreview(
        activeDate: D | null,
        currentRange: DateRange<D>
    ): DateRange<D> {
        this.countDaysNotAvaibleBetweenRange = 0;

        if (currentRange.start && !currentRange.end) {
            const fromDate: any = currentRange.start;
            const toDate: any = activeDate;

            // Get Number not Avaible during Range
            if (currentRange.start && activeDate) {

                const offset = new Date().getTimezoneOffset();

                this.countDaysNotAvaibleBetweenRange = this.dateNotAvaible.filter((date: any) => {
                    return date.day.getTime() >= fromDate.getTime() &&
                        date.day.getTime() <= toDate.getTime();
                }).length - 1

                if (this.delta == 0) {
                    this.countDaysNotAvaibleBetweenRange = 0;
                }

            }

            const maxDate = this._dateAdapter.addCalendarDays(
                currentRange.start,
                this.delta + this.countDaysNotAvaibleBetweenRange
            );

            const rangeEnd = activeDate
                ? activeDate > maxDate
                    ? maxDate
                    : activeDate
                : null;

            return new DateRange(currentRange.start, rangeEnd);
        }

        return new DateRange<D>(null, null);
    }
}

/*  -------------------------------- / MAX RANGE STRATEGY --------------------------------  */


@Component({
    selector: 'formly-datepicker-range-inline-type',
    template: `

    <div class="legend-column">

        <p>Légende : </p>

        <div class="legend">
            <p class="special-date"> <span>24</span> </p> <span class="legend-text"> Jours réservés par d'autres
                établissements</span>
        </div>

        <div class="legend">
            <p class="my-date"> <span>24</span> </p> <span class="legend-text"> Jours réservés par mon
                établissement</span>
        </div>

        <div class="legend">
            <p class="avaible-date"> <span>24</span> </p> <span class="legend-text"> Jours disponibles</span>
        </div>

        <mat-divider></mat-divider>

        <ng-container [ngSwitch]="numberDaysLeft">

            <mat-label *ngSwitchCase="-1" >
                Sélectionnez au maximum 7 jours
            </mat-label>

            <mat-label *ngSwitchCase="0"  class="mat-warn-color">
                Vous ne pouvez plus réserver de jours pour ce mois-ci
            </mat-label>

            <mat-label *ngSwitchDefault class="mat-primary-color" >
            {{ "Vous pouvez encore réserver "+ numberDaysLeft +" jours ce mois-ci"}}
            </mat-label>

        </ng-container>

    </div>


      <div class="disabled-overlay" *ngIf="to['disabled']">
        <i>Veuillez remplir les précédents champs</i>
      </div>

      <mat-calendar *ngIf="props['dateNotAvaible']"
        [selected]="selectedDateRange" 
        (selectedChange)="onDateRangeSelected($event)"
        [dateClass]="dateClass()"
        [dateFilter]="dateFilter"
        [maxDate]="maxDate"
      ></mat-calendar>
  `,
    styles: [
        `
         ::ng-deep .mat-calendar-body-cell-content{
          font-weight: bold;
          font-size: 14px;
        }

        ::ng-deep .previous-date  span{
           color: #9E9E9E !important;
        }

        ::ng-deep .my-date {
                background-color: rgba(0, 0, 255, 0.1)!important;
                border-radius:50%;
                width: 90%!important;
                height: 90%!important;
             span{
           color: #03328a !important;
        }
        } 

        ::ng-deep .special-date {
        //    background-color: red !important;
            span{
                cursor: not-allowed;
                text-decoration: line-through;
                color: #9E9E9E !important;
            }
        }
        .avaible-date{
            color : #000;
        }

        mat-label{
            font-size: 14px;
        }
        .mat-warn-color,.mat-primary-color{
            font-weight:bold;
        }

        .legend-column{
            display:flex;
            flex-direction:column;
            gap:10px;

            p {
                font-size:12px;
                margin-bottom:0px;
                font-weight: bold;
            } 

            .legend {
                display: flex;
                align-items: center;
                gap: 10px;
                p {
                    margin-bottom:0px;
                    width:24px!important;
                    height:24px!important;
                    display:flex;
                    justify-content:center;
                    align-items:center;
                    font-size: 12px;
                }
                span.legend-text {
                    font-size:12px;
                }
            }

        }

        .disabled-overlay {
            background-color: rgba(0, 0, 255, 0.1);
            position:absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;    
            z-index: 999;
            display: flex;
            justify-content: center;
            align-items: center;
            backdrop-filter: blur(3px);
            color: rgba(0, 0, 0, 0.6);
        }
        `
    ],
    providers: [
        {
            provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
            useClass: MaxRangeSelectionStrategy
        },
        MaxRangeSelectionStrategy,
        MatRangeDateSelectionModel
    ]
})
export class DateRangePickerInlineFieldType extends FieldType {
    // https://stackblitz.com/edit/angular-dfqwce?file=src%2Fapp%2Fdate-range-picker-overview-example.ts
    selectedDateRange!: DateRange<Date>;
    maxDate: any = new Date();
    establishment: any;
    numberDaysLeft: number = -1;
    isAdmin: boolean = false;

    constructor(
        private readonly selectionModel: MatRangeDateSelectionModel<Date>,
        private readonly selectionStrategy: MaxRangeSelectionStrategy<Date>,
        @Inject(MAT_DATE_RANGE_SELECTION_STRATEGY)
        private maxRangeStrategy: MaxRangeSelectionStrategy<any>
    ) {
        super();
        // Max Range for Select is date + 3 months
        this.maxDate = this.maxDate.setMonth(this.maxDate.getMonth() + 3);
        this.maxDate = new Date(this.maxDate)
    }


    ngOnInit(): void {
        // If View Establishment init default value
        if (this.field.form?.get('establishment')) {
            this.establishment = this.field.form?.get('establishment')?.value
        }

        // Check if User isAdmin
        if (this.props['isAdmin']) {
            this.isAdmin = this.props['dateNotAvaible'];
        }

        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
        this.field.form?.valueChanges.subscribe((newValue: any) => {

            if (this.field.props) {
                // Faites quelque chose avec la nouvelle valeur de 'establishment'
                if (this.field.props['formControlToObserve']) {
                    // Update Establishement 
                    if (newValue[this.field.props['formControlToObserve']]) {
                        this.establishment = newValue[this.field.props['formControlToObserve']].id
                    }
                }

                this.maxRangeStrategy.dateNotAvaible = this.props['dateNotAvaible'];
            }
        });
    }

    ngAfterViewInit(): void {
        // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        // Add 'implements AfterViewInit' to the class.
        if (this.props['maxRange']) {
            this.maxRangeStrategy.delta = this.props['maxRange'];
        }

        if (this.props['dateNotAvaible']) {
            this.maxRangeStrategy.dateNotAvaible = this.props['dateNotAvaible'];
        }


        // If View Establishment init default value
        if (this.field.form?.get('establishment')) {
            this.establishment = this.field.form?.get('establishment')?.value
        }

    }

    onDateRangeSelected(selectedDate: any) {

        this.maxRangeStrategy.delta = this.getNumberDaysReservedInTheMonth(selectedDate);
        this.numberDaysLeft = this.maxRangeStrategy.delta;
        if (selectedDate) {
            const selection = this.selectionModel.selection,
                newSelection = this.maxRangeStrategy.selectionFinished(
                    selectedDate,
                    selection
                );

            this.selectionModel.updateSelection(newSelection, this);
            this.selectedDateRange = newSelection;
            this.getFormControl().setValue(newSelection);

            if (this.selectionModel.isComplete()) {
                this.getFormControl().setValue(newSelection);
            }
        }

    }


    dateClass() {

        return (date: Date): MatCalendarCellCssClasses => {
            // Today and Not Avaible
            if (date.getDate() == new Date().getDate() && this.dateIncludeInDateNotAvaible(date)) {

                return 'special-date';
            }
            // Past dates
            else if (date < new Date()) {
                return 'previous-date';
            }
            // Date alreary taken
            else if (this.props['dateNotAvaible']) {
                if (this.dateIncludeInDateNotAvaible(date)) {
                    const indexDateDay = this.props['dateNotAvaible'].findIndex((item: any) => item.day.getDate() == date.getDate() && item.day.getMonth() == date.getMonth() && item.day.getFullYear() == date.getFullYear())
                    if (this.props['dateNotAvaible'][indexDateDay]) {

                        const item = this.props['dateNotAvaible'][indexDateDay];
                        if (item['establishment'] == this.establishment) return 'my-date'

                    }

                    return 'special-date';
                }
                return ""
            }
            else {
                return '';
            }
        };
    }

    dateIncludeInDateNotAvaible(date: Date): any {
        var result = false;
        for (let i = 0; i < this.props['dateNotAvaible'].length; i++) {
            if (this.props['dateNotAvaible'][i].day.getDate() == date.getDate() && this.props['dateNotAvaible'][i].day.getMonth() == date.getMonth() && this.props['dateNotAvaible'][i].day.getFullYear() == date.getFullYear()) {
                result = true;
                break;
            }
        }
        return result
    }

    dateFilter = (d: Date): boolean => {
        var result = true;
        const curDate = new Date();
        curDate.setHours(0, 0, 0, 0);

        if (d < curDate) {
            result = false;
        } else {
            for (let i = 0; i < this.props['dateNotAvaible'].length; i++) {
                if (this.props['dateNotAvaible'][i].day.getDate() == d.getDate() && this.props['dateNotAvaible'][i].day.getMonth() == d.getMonth() && this.props['dateNotAvaible'][i].day.getFullYear() == d.getFullYear()) {
                    result = false;
                    break;
                }
            }
        }
        return result;
    }

    getFormControl(): FormControl {
        return this.field.formControl as FormControl;
    }

    getNumberDaysReservedInTheMonth(date: Date) {
        let numberDaysReserved = 0;
        if (!this.isAdmin) {
            const firstDateMonth = new Date(date.getFullYear(), date.getMonth(), 1);
            const lastDateMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

            const filteredDates = this.props['dateNotAvaible'].filter((date: any) => date.establishment == this.establishment && date.day >= firstDateMonth && date.day <= lastDateMonth);

            numberDaysReserved = 7 - filteredDates.length;
        }
        return numberDaysReserved < 0 ? 0 : numberDaysReserved;
    }

}

