import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-text',
  template: `
    <div [ngClass]="['alert',field.props['type']?field.props['type']:'']">
    <svg-icon *ngIf="field.props['addonLeft']" [src]="'assets/icon/'+ field.props['addonLeft'].icon +'.svg' ">  </svg-icon>

        <span>{{field.props['text']?field.props['text']:''}}</span>
    </div>
  `,
  styles: [
    `
    svg-icon{
      display: flex;
      jusitfy-content: center;
      align-items:center;
    }
    .alert {
        display: flex;
        jusitfy-content: start;
        align-items:center;
        gap:10px;
        padding: 15px 20px;
        border-radius: 10px;
        margin-bottom:10px;
        background-color: rgba(0, 0, 0, 0.1);
        &.info {
            background-color: rgba(0, 0, 255, 0.1);
        }
        &.warning {
            background-color: rgba(255, 157, 0, 0.1);
        }
        &.danger {
            background-color: rgba(255, 0, 0, 0.1);
        }
       
    }
    `
  ]
})
export class AlertFieldType extends FieldType<FieldTypeConfig> { }