import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'formly-repeat-section',
  template: `
            <div>

                <div class="flex justify-space-between align-center">
                    <h4> {{ props.label }} </h4>
                    <button mat-stroked-button color="primary" (click)="add()"><mat-icon>add</mat-icon> {{ props['addText']}}</button>
                </div>

                <div *ngFor="let field of field.fieldGroup; let i = index" class="flex">

                    <formly-field class="col" [field]="field" class="w-75"></formly-field>

                    <div class="btn-wrapper">
                        <button mat-icon-button color="warn" (click)="remove(i)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>

                </div>

            </div>
  `,
  styles: [
    `
      .flex{
        display:flex;
        justify-content:flex-start;
        align-items:flex-start;
        gap:10px;
      }
      .justify-space-between{
         justify-content:space-between;
      }
      .align-center{
        align-items:center;
      }
      .btn-wrapper{
        height:56px;
        display:flex;
        align-items:center;
      }
      .w-75{
        width:75%;
      }
    `
  ]

})
export class RepeatTypeComponent extends FieldArrayType {
}