import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-datetime-picker',
  template: `
          <mat-form-field style="width:100%;">
            <input matInput [ngxMatDatetimePicker]="picker" [placeholder]="to['placeholder']??'Sélectionner une date'" [formControl]="formControl">
            <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker [showSpinners]="true" [showSeconds]="to['showSeconds']??false"
              [stepHour]="1" [stepMinute]="1" [stepSecond]="1" [touchUi]="false"
              [color]="'primary'" [enableMeridian]="false">
            </ngx-mat-datetime-picker>
          </mat-form-field>
  `,
  styles: [
    `

       `
  ]
})
export class FormlyFieldDatetimepicker extends FieldType<FieldTypeConfig> {
  @ViewChild('picker') picker: any;

  public dateControlMinMax = new FormControl(new Date());

}