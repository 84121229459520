import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { isObservable, of } from 'rxjs';

@Component({
    selector: 'formly-icon-select-field',
    template: `
    <mat-form-field class="w-100">

        <mat-icon *ngIf="field.props['addonLeft'].icon" matPrefix [ngStyle]="{paddingRight:'15px',paddingLeft:'10px'}">
            <svg-icon [src]="'assets/icon/'+getSelectedIcon()+'.svg'" fxLayout="row"
                fxLayoutAlign="center center"></svg-icon>
        </mat-icon>

        <mat-label>{{ to.label }}</mat-label>
        <mat-select [formControl]="formControl" [placeholder]="to.placeholder!" [multiple]="to['multiple']!" [formlyAttributes]="field" class="w-100" [(value)]="selectedItem" [required]="field.props.required?true:false">
            <mat-option *ngFor="let option of optionsList" [value]="getValuePattern(option)" >
                <mat-icon>
                    <svg-icon [src]="'assets/icon/'+option.icon+'.svg'" fxLayout="row"
                        fxLayoutAlign="center center"></svg-icon>
                </mat-icon>
                     {{to['labelProp']?option[to['labelProp']]: option.label }}
            </mat-option>
        </mat-select>

    </mat-form-field>
  `,
})
export class SelectIconFieldType extends FieldType<FieldTypeConfig> {
    optionsList: any = [];
    selectedItem: Array<any> = [];

    binData() {
        if (this.formControl.defaultValue) {
            this.selectedItem = this.formControl.defaultValue.map((opt: any) => this.getValuePattern(opt))
            this.formControl.setValue(this.selectedItem)
        }
    }

    ngOnInit(): void {
        this.binData();
        if (this.field.props.options) {
            // Case Observable Input
            if (isObservable(this.field.props.options)) {
                this.field.props.options.subscribe(data => {
                    this.optionsList = data
                })
            } else { // Case default Value
                this.optionsList = this.field.props.options;
            }
        }

        // this.selectedItem = this.formControl.value ? this.optionsList.filter((o: any) => this.formControl.value.includes(o.value)) : [];
        // this.formControl.setValue(this.selectedItem);

        // Listen to value changes of the form control
        this.formControl.valueChanges.subscribe((value) => {
            this.selectedItem = value;
        });

    }
    getValuePattern(option: any) {
        return this.field.props['valueProp'] ? this.field.props['valueProp'](option) : option;
    }

    getSelectedIcon() {
        if (this.selectedItem.length == 1) {
            const itemReferenceInOptionList = this.optionsList.find((item: any) => JSON.stringify(item).includes(this.selectedItem[0]))
            return itemReferenceInOptionList ? itemReferenceInOptionList['icon'] : this.field.props['addonLeft'].icon;
        } else {
            return this.field.props['addonLeft'].icon;
        }
    }

    resolvePath = (object: object, path: any, defaultValue: any) => path.split('.').reduce((o: any, p: any) => o ? o[p] : defaultValue, object)

}
