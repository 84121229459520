<ng-container *ngIf="data.config">
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="20px"
    class="dialog-title"
    *ngIf="data.config.header"
  >
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <!-- 
      <svg-icon
        *ngIf="data.config.header.icon"
        [src]="'assets/icon/' + data.config.header.icon + '.svg'"
        fxLayout="row"
        fxLayoutAlign="center center"
        class="mat-primary-color"
        [svgStyle]="{ 'width.px': 32, 'height.px': 32 }"
      ></svg-icon>
     -->
      <h3 class="dialog-name" *ngIf="data.config.header.label">
        {{ data.config.header.label }}
      </h3>
    </div>
    <button mat-icon-button [mat-dialog-close]="true">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content
    class="mat-typography"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="30px"
    *ngIf="data.config.content"
  >
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
      <svg-icon
        *ngIf="data.config.content.img"
        [src]="
          'assets/icons/' + data.config.content.img
            ? data.config.content.img
            : 'delete-dialog.svg'
        "
        fxLayout="row"
        fxLayoutAlign="center center"
        class="mat-warn-color"
      ></svg-icon>

      <h3 class="mat-warn-color dialog-title" *ngIf="data.config.content.title">
        {{ data.config.content.title }}
      </h3>
      <p class="dialog-text" *ngIf="data.config.content.text">
        {{ data.config.content.text }}
      </p>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
      class="dialog-actions"
    >
      <button mat-stroked-button mat-dialog-close>
        {{
          data.config.actions.cancel ? data.config.actions.cancel : "Annuler"
        }}
      </button>
      <button mat-flat-button color="warn" (click)="submitDialogForm()">
        {{
          data.config.actions.submit ? data.config.actions.submit : "Supprimer"
        }}
      </button>
    </div>
  </mat-dialog-content>
</ng-container>
