import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '@env';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { StripeService } from 'ngx-stripe';
import { Observable, switchMap } from 'rxjs';

@Component({
    selector: 'formly-field-resume',
    template: `
  <div class="column gap-30">
       <div class="column gap-10">
    <div class="row gap-10 justify-start">
        <svg-icon class="mat-primary-color" [src]="'assets/icon/'+ (field.props['addonLeft'] && field.props['addonLeft'].icon ? field.props['addonLeft'].icon : 'ticket-bulk' )+'.svg' " fxLayout="row"
        fxLayoutAlign="center center"></svg-icon>
        <h4>Détails de votre commande</h4>
    </div>
    <mat-divider style="border-top-width: 1.5px;"></mat-divider>
</div>

    <div class="column gap-10">

        <div class="row gap-10">
            <span>{{numberDays}} x {{ dayPrice }} jours</span>
            <span>{{getTotalToPay() }}€</span>
        </div>

    <div *ngIf="dateListForUser.length>0" class="days-list">
        <span class="title">Liste des jours réservés :</span>
        <ul>
            <li *ngFor="let date of dateListForUser">
                {{ date | date: 'dd/MM/yyyy' }}
            </li>
        </ul>
    </div>
        <div class="row gap-10" *ngIf="discount>0">
            <span>Code promo</span>
            <span> - {{discount }} €</span>
        </div>

    </div>

    <div class="total row gap-10">
        <span>Total :</span>
        <span>{{ getTotalToPay()  }} €</span>
    </div>



    <ng-container *ngIf="field.props['payment'] && field.props['payment'].type =='stripe'; else classicBtn">
        <button mat-flat-button color="primary" (click)="checkout(field.props['payment'].product??'')"  [disabled]="!field.form?.valid || numberDays<1 || !props['payment'].idEstablishment" >
            <div class="row justify-center gap-10">
            <svg-icon class="row" src="assets/icon/bank-card-bulk.svg"></svg-icon>
                <span>
                    Payer {{ getTotalToPay() }} €
                </span>
            </div>
        </button>
    </ng-container>

    <ng-template #classicBtn>
        <button mat-flat-button color="primary" [disabled]="!field.form?.valid || numberDays<1" >
            <div class="row justify-center gap-10">
                <svg-icon class="row" src="assets/icon/bank-card-bulk.svg"></svg-icon>
                    <span>
                        Payer {{  getTotalToPay() }} €
                    </span>
                </div>
            </button>
    </ng-template>    
    
</div>
  `,
    styles: [
        `
        ::ng-deep formly-field {
            position:relative;
        }
        button {
            position:absolute;
            bottom:24px;
            left:24px;
            right:24px;
            span {
                font-weight: bold;
            }
        }
        .row {
            display:flex;
            justify-content:space-between;
            align-items:center;
        }
        .justify-start {
            justify-content:flex-start;
        }
        .justify-center {
            justify-content:center;
        }
        .column {
            display:flex;
            flex-direction:column;
            justify-content:flex-start;
            align-items:strech;
        }
        .gap-10 {
            gap:10px;
        }
        .gap-30 {
            gap:30px;
            color:#000;
        }
        .days-list {

            ul {
                margin-top:0px;
                 font-size:14px;
            }
        }
        .total {
            background-color: #FAFAFA;
            border-radius:8px;
            padding:20px 15px;
        }
        h4 {
            font-size:18px;
            font-weigth:600!important;
            margin-bottom:0px;
            }

            // Mobile
            @media only screen and (max-width: 599px) {

                .total{
                margin-bottom:75px;
                }
                button{
             //       margin-top:-50px;
              
                }
            }
       `
    ]
})

export class ResumePriceFieldType extends FieldType<FieldTypeConfig> {
    public numberDays: number = 0;
    public dayPrice: number = 0;
    public discount: number = 0;
    private isAdmin: boolean = false;
    private endpoint: string = environment.apiUrl;
    dateListForServer: Date[] = [];
    dateListForUser: Date[] = [];

    constructor(private stripeService: StripeService, private http: HttpClient, private _snackBar: MatSnackBar) {
        super();
    }

    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
        this.getFormControl()

        // Check if Admin
        this.isAdmin = this.field.props['payment']['isAdmin'];

        this.field.form?.valueChanges.subscribe((newValue: any) => {

            // Faites quelque chose avec la nouvelle valeur de 'dateRange'
            if (this.field.props['formControlToObserve']) {
                this.onModelUpdate(newValue[this.field.props['formControlToObserve']]);
            }
            if (newValue['establishment']) {
                this.onModelUpdateEstablishment(newValue['establishment'])
            }
        });

    }

    getFormControl(): FormControl {
        return this.field.formControl as FormControl;
    }

    getTotalToPay() {
        if (this.isAdmin) return 0;
        const numberDayPriceOne = this.numberDays <= 2 ? this.numberDays : 2;
        const numberDayPriceTwo = this.numberDays > 2 ? this.numberDays - 2 : 0;
        const globalPrice = numberDayPriceOne * 20 + numberDayPriceTwo * 10;
        return (globalPrice - this.discount) < 0 ? 0 : globalPrice - this.discount;
    }

    // Check model every second
    onModelUpdate(newValue: any) {
        var { start, end } = newValue;
        this.dateListForServer = [];
        this.dateListForUser = [];

        this.numberDays = this.daysDiffBetweenDates(start, end);
        if (this.field.props['dayPrice']) this.dayPrice = this.field.props['dayPrice'];
        if (this.field.props['discount']) this.discount = this.field.props['discount'];
    }

    onModelUpdateEstablishment(establishement: any) {
        this.props['payment'].idEstablishment = establishement.id;
    }

    daysDiffBetweenDates(dateDebut: any, dateFin: any) {
        if (dateFin == null) {
            if (dateDebut == null) {
                return 0;
            } else {
                this.dateListForServer.push(new Date(dateDebut));
                const offset = new Date().getTimezoneOffset();
                const dateUtcFrance = new Date(dateDebut).getTime() - offset * 60000;
                this.dateListForUser.push(new Date(dateUtcFrance))
                return 1;
            }
        } else {
            this.generateDateList(dateDebut, dateFin)
            return this.dateListForServer.length
        }
    }

    getCountDaysNotAvaible(dateDebut: any, dateFin: any) {
        if (this.props['dateNotAvaible'] && this.props['dateNotAvaible'].length > 0) {
            // Get Number not Avaible during Range
            var countDaysNotAvaibleBetweenRange = this.props['dateNotAvaible'].filter((date: any) => {
                return date.getTime() >= dateDebut.getTime() &&
                    date.getTime() <= dateFin.getTime();
            }).length
            return countDaysNotAvaibleBetweenRange;
        } else {
            return 0;
        }
    }

    generateDateList(dateDebut: any, dateFin: any) {
        const offset = new Date().getTimezoneOffset();
        this.dateListForServer = [];
        this.dateListForUser = [];
        const currentDate = new Date(dateDebut);

        while (currentDate <= dateFin) {
            if (this.props['dateNotAvaible']) {
                if (this.props['dateNotAvaible'].filter((item: any) => new Date(item).getDate() == currentDate.getDate() && new Date(item).getMonth() == currentDate.getMonth() && new Date(item).getFullYear() == currentDate.getFullYear()).length == 0) {
                    this.dateListForServer.push(new Date(currentDate));
                    const dateUtcFrance = new Date(currentDate).getTime() - offset * 60000;
                    this.dateListForUser.push(new Date(dateUtcFrance))
                }
            } else {
                this.dateListForServer.push(new Date(currentDate));
                const dateUtcFrance = new Date(currentDate).getTime() - offset * 60000;
                this.dateListForUser.push(new Date(dateUtcFrance))
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }

    }

    /* ------------------------------ STRIPE PAYMENT ------------------------------ */

    // Open the checkout handler
    checkout(priceId: any) {
        const promoted = {
            area: this.model.area,
            establishment: this.model.establishment.id ? this.model.establishment.id : this.model.establishment ? this.model.establishment : this.props['payment'].idEstablishment,
            days: this.dateListForUser.map(date => date.toISOString().split('T')[0])
        }

        // Admin Payement
        if (this.isAdmin) {
            this.createPromotedOrder(promoted).subscribe(
                data => {
                    const orderNumber = data['order_number'];
                    if (orderNumber) {
                        this.confirmOrderPayment(orderNumber).subscribe(data => {
                            this.openSnackBar("SUCCESS : Votre réservation a bien été prise en compte !", 'Fermer')
                        });
                    }
                },
                err => {
                    this.openSnackBar(" ERREUR : Une réservation a déjà été pré-sauvegardé pour ce créneau !", 'Fermer')
                },
                () => console.log('Launch Admin Paiement')
            )
        }
        // Establishment Payement
        else {
            this.createPromotedOrder(promoted).subscribe(
                data => {
                    const orderNumber = data['order_number'];
                    if (orderNumber) {
                        const numberDayPriceOne = this.numberDays <= 2 ? this.numberDays : 2;
                        const numberDayPriceTwo = this.numberDays > 2 ? this.numberDays - 2 : 0;

                        let arr_products = [];

                        if (numberDayPriceOne > 0) {
                            arr_products.push({
                                price_id: 'price_1OG04bJl0ssdwhsi0hUEb0He',
                                quantity: numberDayPriceOne,
                            });
                        }

                        if (numberDayPriceTwo > 0) {
                            arr_products.push({
                                price_id: 'price_1OG05mJl0ssdwhsiFKJTjCG6',
                                quantity: numberDayPriceTwo,
                            });
                        }

                        this.http.post<any>(`${this.endpoint}/api/payments`, {
                            cancel_url: `${window.location.origin}/payment-cancel/${this.props['payment'].idEstablishment}/${orderNumber}`,
                            success_url: `${window.location.origin}/payment-success/${this.props['payment'].idEstablishment}/${orderNumber}`,
                            products: arr_products,
                            //  test: true // dev
                            test: false // prod
                        }).pipe(
                            switchMap(session => {
                                return this.stripeService.redirectToCheckout({ sessionId: session.id })
                            })
                        )
                            .subscribe(result => {
                                // If `redirectToCheckout` fails due to a browser or network
                                // error, you should display the localized error message to your
                                // customer using `error.message`.
                                if (result.error) {
                                    alert(result.error.message);
                                }
                            });
                    }
                },
                err => {
                    this.openSnackBar(" ERREUR : Une réservation a déjà été pré-sauvegardé pour ce créneau !", 'Close')
                },
                () => console.log('Launch Stripe Paiement')
            )
        }

    }

    createPromotedOrder(promoted: any): Observable<any> {
        return this.http.post<any>(`${this.endpoint}/api/promoted`, promoted);
    }

    confirmOrderPayment(id: string): Observable<any> {
        return this.http.post<any>(`${this.endpoint}/api/promoted/confirm-order-payment/${id}`, {});
    }

    /**
    * Triggers the display of a pop up message
    * @param message Message display ex. 'Hello world !'
    * @param action  Message display in close btn
    */
    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, {
            horizontalPosition: "center",
            verticalPosition: "top",
            duration: 4 * 1000,
        });
    }
    /* ------------------------------ / STRIPE PAYMENT ------------------------------ */

}