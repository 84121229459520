import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
    selector: 'formly-field-chip-list',
    template: `
        <mat-chip-listbox [formControl]="getFormControl()" multiple (change)="onChipListChange($event)" >
            <mat-chip-option *ngFor="let option of chipList | async" [removable]="true" [value]="getValue(option)" [selected]="isSelected(option)">
                <div class="flex-chip">
                    <svg-icon *ngIf="option.icon" [src]="'assets/icon/'+option.icon+'.svg'" fxLayout="row" fxLayoutAlign="center center"></svg-icon>
                    {{ option.name | titlecase }}
                </div>
            </mat-chip-option>
        </mat-chip-listbox>
  `,
    styles: [
        `
    .step-content{
      padding-top:12px;
    }

    .flex-btn-group{
      display:flex;
      justify-content:flex-end;
      gap:20px;
    }
    .flex-chip{
      display:flex;
      justify-content:center;
      align-items:center;
      gap:10px;
    }
    mat-chip-listbox{
        margin-bottom:75px;
    }
    .mdc-evolution-chip-set .mdc-evolution-chip {
        margin-top: 6px;
        margin-bottom: 6px;
    }

    .mdc-evolution-chip-set .mdc-evolution-chip {
        margin-left: 10px;
        margin-right: 0;
    }
    `
    ]
})
export class ChipListFieldType extends FieldType {
    chipList: any = []
    lastRemovedOption: any = [];

    ngOnInit(): void {
        this.chipList = this.field.props?.options || [];
        // Set default value when initializing the form control
        const defaultValue = this.field.props?.['defaultValue'];
        if (defaultValue) {
            this.getFormControl().setValue(defaultValue);
        }
    }

    onChipListChange(event: any) {
        const formControl = this.getFormControl();
        if (!formControl) return;

        // Select All Options
        const allOption = event.value.find((option: any) => option.name === 'all');
        if (allOption) {
            formControl.setValue([...this.chipList]);
        }

        // Check if Last remove is "All"
        this.lastRemovedOption = this.lastRemovedOption.filter((x: any) => !event.value.includes(x));
        const allDeleted = this.lastRemovedOption.find((option: any) => option.name === 'all');

        if (allDeleted) {
            formControl.setValue([])
        } else {
            // TODO : Cas select all -> delesec item
        }
        this.lastRemovedOption = event.value
    }

    getFormControl(): FormControl {
        return this.field.formControl as FormControl;
    }

    getValue(option: any): any {
        if (this.field.props && this.field.props['valueProp']) {
            return this.field.props['valueProp'](option);
        }
        return option;
    }

    isSelected(value: any): boolean {
        const formControl = this.getFormControl();
        if (!formControl) return false;
        // Check if the value is selected in the FormControl's value
        const selectedValues = formControl.value || [];
        return JSON.stringify(selectedValues).includes(value.id ? value.id : value);
    }

}