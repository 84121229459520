// auth.actions.ts

import { createAction, createActionGroup, props } from '@ngrx/store';
import { AppState } from './app.state';

export const selectAccessToken = (state: AppState) => state.auth.accessToken;

export const AuthActions = createActionGroup({
    source: 'Auth',
    events: {
        /*
                'Sign in request': props<{ username: string, password: string }>(),
                'Sign up request': props<{ first_name: string, last_name: string, email: string, password: string }>(),
                'Sign out request': props<{ id: string }>(),
                'Sign out success': props<any>(),
                'Sign in success': props<{ access_token: string, refresh_token: string }>(),
                'Sign in failure': props<{ error: string }>(),
                'Fetch user': props<{ access_token: string }>(),
                'Fetch user success': props<any>(),
                'Fetch user failure': props<{ error: string }>(),
                'Refresh': props<{ refresh_token: string }>(),
        */

        'Sign In': props<{ username: string; password: string, user?: any }>(),
        'Sign In Success': props<{ accessToken: string, refreshToken: string }>(),
        'Sign In Failure': props<{ error: string }>(),
        'Sign Out': props<any>(),
        'Refresh Token': props<any>(),
        'Refresh Token Success': props<{ accessToken: string, refreshToken: string, user: any }>(),
        'Not Logged': props<any>(),
        'Get User Profile': props<any>(),
        'Get User Profile Success': props<{ user: any }>(),
        'Get User Profile Failure': props<{ error: any }>(),
        'Update User Profile': props<{ user: any }>(),
    },
});
