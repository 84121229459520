import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';


@Component({
  selector: 'formly-field-hour',
  template: `
    <mat-form-field>
      <mat-label>{{props.label}}</mat-label>
      <input matInput type="time" [formControl]="formControl" [formlyAttributes]="field">
    </mat-form-field>
  `,
  styles: [
    `
    mat-form-field{
      width:100%;
    }

    ::ng-deep  .mdc-text-field{
    border:1px solid transparent;
    }

   ::ng-deep .safari-empty-hour{
      color:rgba(0, 0, 0, 0.54);
      cursor: default;

    .mdc-text-field{
      background-color: #efefef!important;
      border: 1px dashed rgba(0, 0, 0, 0.75);
      opacity:0.5;
        mat-label,input{
          color: rgba(0, 0, 0, 0.75)!important;;
        }
      }
    }
     `]
})
export class HourPickerFieldType extends FieldType<FieldTypeConfig> {

}