import {
    Directive,
    Output,
    Input,
    EventEmitter,
    HostBinding,
    HostListener
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
    selector: '[appDnd]'
})
export class DndDirective {
    @HostBinding('class.fileover')
    fileOver!: boolean;
    @Output() fileDropped = new EventEmitter<any>();

    constructor(private sanitizer: DomSanitizer) { }

    // Dragover listener
    @HostListener('dragover', ['$event']) onDragOver(evt: any) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = true;
    }

    // Dragleave listener
    @HostListener('dragleave', ['$event']) public onDragLeave(evt: any) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;
    }

    // Drop listener
    @HostListener('drop', ['$event']) public ondrop(evt: any) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;
        let files = evt.dataTransfer.files;
        if (files.length > 0) {
            // Add Url
            for (let i = 0; i < files.length; i++) {
                const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(files[i]));
                files[i]['url'] = url;
            }
            this.fileDropped.emit(files);
        }
    }

}
