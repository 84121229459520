// auth.interceptor.ts
import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, filter, switchMap, take, throwError } from 'rxjs';
import { AuthService } from '../service/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private authService: AuthService) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        const accessToken = localStorage.getItem("access_token");
        const refreshToken = localStorage.getItem("refresh_token");

        // Vérifiez si l'URL contient "/auth/refresh" et n'ajoutez pas le token access à la requête.
        if (request.url.includes('/auth/refresh') && refreshToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + refreshToken,
                },
            });
        } else if (accessToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + accessToken,
                },
            });
        }

        return next.handle(request).pipe(catchError(error => {
            if (error instanceof HttpErrorResponse && !request.url.includes('auth/signin') && error.status === 401) {
                return this.handle401Error(request, next);
            }
            return throwError(error);
        }));;
    }

    /**
     * Refresh Token Management
     * @param request 
     * @param next 
     * @returns 
     */
    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            const refreshToken = localStorage.getItem("refresh_token");
            if (refreshToken) {
                return this.authService.refreshToken().pipe(
                    switchMap((token: any) => {
                        this.isRefreshing = false;
                        const access_token = token.access_token
                        localStorage.setItem("access_token", access_token);
                        localStorage.setItem("refresh_token", token.refresh_token);
                        this.refreshTokenSubject.next(access_token);
                        return next.handle(this.addTokenHeader(request, access_token));
                    }),
                    catchError((err) => {
                        this.isRefreshing = false;
                        this.authService.signOut();
                        return throwError(err);
                    })
                );
            }
        }

        return this.refreshTokenSubject.pipe(
            filter(refreshToken => refreshToken !== null),
            take(1),
            switchMap((refreshToken) => next.handle(this.addTokenHeader(request, refreshToken)))
        );
    }

    private addTokenHeader(request: HttpRequest<any>, token: string) {
        /* for Node.js Express back-end */
        return request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
    }



}
