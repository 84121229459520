import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MaterialModule } from './material.module';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { FormlyModule } from '@ngx-formly/core';

import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { TextFieldType } from './formly/custom-input/text-input';
import { DividerFieldType } from './formly/custom-input/divider-input';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { FlexLayoutModule } from '@angular/flex-layout';
import { OfferCardComponent } from './components/offer-card/offer-card.component';
import { HourPickerFieldType } from './formly/custom-input/hour-input';
import { TimeRangeWrapperComponent } from './formly/custom-wrapper/time-range.wrapper';
import { FlexLayoutType } from './formly/custom-input/flex-layout.type';
import { FormlyWrapperAddons } from './formly/custom-wrapper/icons.wrapper';
import { addonsExtension } from './formly/custom-wrapper/addons.extension';
import { SelectIconFieldType } from './formly/custom-input/select-icon.type';
import { RepeatTypeComponent } from './formly/custom-input/repeat.type';
import { FilterCardComponent } from './components/filter-card/filter-card.component';
import { FormlyFieldStepper } from './formly/custom-input/stepper.type';
import { ChipListFieldType } from './formly/custom-input/chip-select.type';
import { DeleteDialogComponent } from './components/dialog/delete-dialog/delete-dialog.component';
import { EditDialogComponent } from './components/dialog/edit-dialog/edit-dialog.component';
import { FormlyFieldFileDragNDrop } from './formly/custom-input/fileDragNDrop.type';
import { DndDirective } from './formly/directives/dnd.directives';
import { FormlyFieldFile } from './formly/custom-input/file.type';
import { CustomTableComponent } from './components/custom-table/custom-table.component';
import { TableDeleteDialogComponent } from './components/custom-table/dialog/delete-dialog/delete-dialog.component';
import { NgChartsModule } from 'ng2-charts';
import { AutocompleteType } from './formly/custom-input/autocomplete.type';
import { DateRangePickerFieldType } from './formly/custom-input/datepicker-range.type';
import { DateRangePickerInlineFieldType } from './formly/custom-input/datepicker-range-inline.type';
import { ResumePriceFieldType } from './formly/custom-input/resume-price.type';
import { NgxStripeModule } from 'ngx-stripe';
import { PaymentDialogComponent } from './components/dialog/payment-dialog/payment-dialog.component';
import { PaymentSuccessfullComponent } from './components/payment-successfull/payment-successfull.component';
import { PaymentCancelComponent } from './components/payment-cancel/payment-cancel.component';
import { AlertFieldType } from './formly/custom-input/alert-text.type';
import { FormlyFieldDatetimepicker } from './formly/custom-input/datetime-picker.type';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { QRCodeModule } from 'angularx-qrcode';
import { SearchbarComponent } from './components/searchbar/searchbar.component';
import { CustomToggleFieldType } from './formly/custom-input/custom-slide-toggle.type';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FlexLayoutModule,
    MaterialModule,
    AngularSvgIconModule.forRoot(),
    FormlyMaterialModule,
    MatNativeDateModule,
    FormlyMatDatepickerModule,
    FormlyMatToggleModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    FormlyModule.forRoot({
      types: [
        { name: 'flex-layout', component: FlexLayoutType },
        { name: 'text', component: TextFieldType },
        { name: 'divider', component: DividerFieldType },
        { name: 'hour', component: HourPickerFieldType },
        { name: 'select-icon', component: SelectIconFieldType },
        { name: 'repeat', component: RepeatTypeComponent },
        { name: 'chip-list', component: ChipListFieldType },
        { name: 'stepper', component: FormlyFieldStepper, wrappers: [] },
        { name: 'file', component: FormlyFieldFile },
        { name: 'fileDragnDrop', component: FormlyFieldFileDragNDrop },
        { name: 'autocomplete', component: AutocompleteType, },
        { name: 'dateTimePicker', component: FormlyFieldDatetimepicker, },
        { name: 'dateRangePicker', component: DateRangePickerFieldType, },
        { name: 'dateRangePickerInline', component: DateRangePickerInlineFieldType, },
        { name: 'resumePrice', component: ResumePriceFieldType },
        { name: 'alert', component: AlertFieldType },
        { name: 'custom-toggle', component: CustomToggleFieldType },
      ],
      wrappers: [
        { name: 'addons', component: FormlyWrapperAddons },
        { name: 'time-range', component: TimeRangeWrapperComponent }],
      extensions: [{ name: 'addons', extension: { onPopulate: addonsExtension } }],

    }),
    NgChartsModule,
    // NgxStripeModule.forRoot('pk_test_51NrcSFJkdeNXoxv7haFuEg62jO2O8VtRFQGKvdS1UFRmO5M5Ab3Qb4Vap5KmKnCl5f6GYsDHkjP3vXrqGAUzwF3X00Q0SUjW8n'), // dev
    NgxStripeModule.forRoot('pk_live_51NvF9BJl0ssdwhsi5oeNYGCEMzb1KRidnauSroL0HHfsVmtD73ApIignGLNlUzPwaYaySp5RlyEi48sYfOZstFO700eA7qWhb1'), // prod


    QRCodeModule,

  ],
  declarations: [
    OfferCardComponent,
    TextFieldType,
    DividerFieldType,
    HourPickerFieldType,
    FlexLayoutType,
    TimeRangeWrapperComponent,
    FormlyWrapperAddons,
    SelectIconFieldType,
    RepeatTypeComponent,
    FilterCardComponent,
    FormlyFieldStepper,
    ChipListFieldType,
    FormlyFieldFile,
    FormlyFieldFileDragNDrop,
    DeleteDialogComponent,
    EditDialogComponent,
    DndDirective,
    CustomTableComponent,
    TableDeleteDialogComponent,
    AutocompleteType,
    DateRangePickerFieldType,
    DateRangePickerInlineFieldType,
    ResumePriceFieldType,
    PaymentDialogComponent,
    PaymentSuccessfullComponent,
    PaymentCancelComponent,
    AlertFieldType,
    FormlyFieldDatetimepicker,
    SearchbarComponent,
    CustomToggleFieldType

  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    FlexLayoutModule,
    OfferCardComponent,
    AngularSvgIconModule,
    FormlyMaterialModule,
    MatNativeDateModule,
    FormlyMatDatepickerModule,
    FormlyModule,
    FilterCardComponent,
    DeleteDialogComponent,
    EditDialogComponent,
    CustomTableComponent,
    NgChartsModule,
    PaymentDialogComponent,
    QRCodeModule,
    SearchbarComponent
  ],
  providers: [

  ]
})
export class SharedModule {
  constructor() {

  }
}
