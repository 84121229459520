import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-time-range',
  template: `
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
     <h4>{{ props.label }} : </h4>   <ng-container #fieldComponent></ng-container>
    </div>
  `, styles: [
    `
      h4{
        width:100px;
      }
      `
  ]
})
export class TimeRangeWrapperComponent extends FieldWrapper { }