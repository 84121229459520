<div
  class="container-fluid full-width-image"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  [class]="currentActiveTheme$"
>
  <!-- Left Part -->
  <!-- 
  <div class="auth-img" fxFlex="45" fxHide fxHide.gt-sm="false">
    <div
      class="auth-logo"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="15px"
    >
  
    </div>
  </div>
-->
  <!-- Auth Part -->
  <div
    class="auth-box"
    fxLayout="column"
    fxLayoutAlign="flex-start center"
    fxFlex="100"
    fxFlex.gt-xs="50"
    fxLayoutGap="40px"
    style="background-color: transparent"
  >
    <!-- Header Auth -->
    <div
      fxLayout="column"
      fxLayoutAlign="space-evenly center"
      fxLayoutGap="15px"
      class="auth-header"
      fxLayoutGap="30px"
      style="background-color: transparent"
    >
      <img
        [src]="authConfig.logo"
        alt="logo"
        class="auth-header-img"
        [srcset]="authConfig.logo"
        width="300px"
      />
      <!--  
      <h1 class="mat-primary-color">{{authConfig.name}}</h1>
      -->
      <!--  
      <p class="text-center text-grey" fxFlex="75" fxFlex.gt-sm="100">
        {{ authConfig.description }}
      </p>
      -->
    </div>

    <!-- Core Auth -->
    <div class="auth-core">
      <router-outlet> </router-outlet>
    </div>

    <!-- Footer Auth -->
    <div></div>
  </div>
</div>
