import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-offer-card',
  templateUrl: './offer-card.component.html',
  styleUrls: ['./offer-card.component.scss']
})
export class OfferCardComponent {

  @Input() data: any;
  @Input() isAdmin: any;
  // isAdmin: boolean = false;
  @Output() editOfferClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteOfferClick: EventEmitter<any> = new EventEmitter<any>();


  isCardActive(dateStart: string, dateEnd: string): boolean {
    const currentDate: Date = new Date();
    return currentDate >= new Date(dateStart) && currentDate <= new Date(dateEnd) || currentDate >= new Date(dateStart) && dateEnd == null ? true : false;
  }

  editOffer() {
    // Émettre l'événement editOfferClick lorsque le bouton "Modifier" est cliqué
    this.editOfferClick.emit(this.data);
  }

  deleteOffer() {
    // Émettre l'événement deleteOfferClick lorsque le bouton "Supprimer" est cliqué
    this.deleteOfferClick.emit(this.data);
  }

  getTimeWithoutMiliseconds(time: string) {
    return time ? time.slice(0, -3).replace(':', 'h') : '';
  }

}
