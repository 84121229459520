import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Role } from '@data/models/role.model';
import { Observable } from 'rxjs';
import { selectLoggedUser } from '../store/auth.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.state';

@Directive({
  selector: '[appHasRole]'
})
export class HasRoleDirective {

  @Input('appHasRole')
  requiredRoles!: Role[];
  user$: Observable<any> = this._store.select(selectLoggedUser)
  rolesList = new Set();
  constructor(
    private _store: Store<AppState>,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }

  ngOnInit() {
    let user = undefined;
    this.user$.subscribe(data => {

      user = (typeof data == 'string' ? JSON.parse(data) : data);

      // If user scope are defined
      if (user.scopes.length > 0) {
        // Add Each Role to the RoleList Set
        user.scopes.forEach((scope: any) => {
          this.rolesList.add(scope.role.name)
        });

        // If Contains same role in Set and requiredRoles allow to show navLink
        if (this.requiredRoles.length == 0 || Array.from(this.rolesList).some((role: any) => this.requiredRoles.includes(role))) {
          this.viewContainer.detach();
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }

      } else {
        this.viewContainer.clear();
      }

    });
  }

}
