import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { MatInput } from '@angular/material/input';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'formly-autocomplete-type',
  template: `
    <mat-form-field class="w-100">
         <mat-icon *ngIf="field.props?.['addonLeft'].icon" matPrefix [ngStyle]="{paddingRight:'15px',paddingLeft:'10px'}">
            <svg-icon [src]="'assets/icon/'+field.props?.['addonLeft'].icon+'.svg'" fxLayout="row"
                fxLayoutAlign="center center"></svg-icon>
        </mat-icon>
      <input matInput
      [matAutocomplete]="auto"
      [formControl]="getFormControl()"
      [formlyAttributes]="field"
      [placeholder]="to.placeholder ? to.required ? (to.placeholder + ' *') : to.placeholder : '' " [required]="to.required??false">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let value of filter | async" [value]="value" >
          {{to['labelProp']?value[to['labelProp']]:value }}
        </mat-option>
      </mat-autocomplete>

      <mat-error>
        <formly-validation-message [field]="field"></formly-validation-message>
      </mat-error>

      <mat-hint *ngIf="to.description">
        {{ to.description }}
      </mat-hint>
      
    </mat-form-field>
  `,
})
export class AutocompleteType extends FieldType {
  filter!: Observable<any>;

  ngOnInit() {
    this.filter = this.formControl.valueChanges.pipe(
      startWith(''),
      switchMap(term => this.props['filter'](term)),
    );

  }

  getFormControl(): FormControl {
    return this.field.formControl as FormControl;
  }

  displayFn = (item: any) => {
    return item && this.field && this.field.props?.['labelProp'] ? item[this.field.props?.['labelProp']] : '';
  }

}
