import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ThemePalette } from '@angular/material/core';
@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private isDarkTheme: BehaviorSubject<boolean>;

  constructor() {
    this.isDarkTheme = new BehaviorSubject<boolean>(
      localStorage.getItem('isDarkTheme') === 'false'
    );
  }

  setDarkTheme(isDarkTheme: boolean) {
    localStorage.setItem('isDarkTheme', this.isDarkTheme.value.toString());
    this.isDarkTheme.next(isDarkTheme);
  }

  getIsDarkTheme(): Observable<boolean> {
    return this.isDarkTheme;
  }

}
