import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '@env';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  endpoint: string = environment.apiUrl;

  constructor(private http: HttpClient, private _snackBar: MatSnackBar) { }


  /**
 * Global Getter
 * @param path API route
 * @returns Observable
 */
  getAPIGlobal(path: string): Observable<any> {
    return this.http.get<any>(`${this.endpoint}/api/${path}`);
  }
  /**
* Global Getter
* @param path API route
* @returns Observable
*/
  getAPIGlobalPaginated(path: string, page: number, limit?: number, search?: string, sort?: string, filter?: string): Observable<any> {
    return this.http.get<any>(`${this.endpoint}/api/${path}?${'page=' + (page + 1) + "&"}${'search=' + (search ? search : "")}${'&limit=' + (limit ? limit : 20)}${'&sortBy=' + sort}`);
  }

}
