<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" class="payment-container light-theme ">

    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="25px">

        <img src="assets/img/oltoo-sad.png" fxLayout="row" fxLayoutAlign="center center" class="mat-primary-color"
            width="100px">


        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
            <h3 class="mat-primary-color dialog-title">Oups, il y a eu des erreurs!
            </h3>
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="0px">
                <p class="dialog-text">Nous avons rencontré des erreurs lors de votre tentative de paiement, aucun
                    montant n'a été débité de votre compte. Nous vous invitions a réssayer ultérieurement </p>
            </div>
        </div>

        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px" class="dialog-actions">
            <button mat-flat-button color="primary" (click)="redirectToEtab()">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                    <span>Retourner à mon établissement</span>
                    <mat-icon>trending_flat</mat-icon>
                </div>
            </button>
            <i class="counter-text">Redirection automatique dans {{counter}} secondes</i>
        </div>

    </div>

</div>